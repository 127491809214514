/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import { Token } from '@uniswap/sdk'
import sousChefABI from 'constants/abis/sousChef.json'
import sousChefABIDeposit from 'constants/abis/sousChefDepost.json'
import wbnbABI from 'constants/abis/weth.json'
import { QuoteToken } from 'constants/config/types'
import erc20 from 'constants/abis/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

export const fetchFarmsBlockLimits = async (farms: any) => {
  const FarmsWithEnd = farms.filter(p => p.sousId !== 0)

  const FarmsWithEndDepositFee = farms.filter(p => p.sousId !== 0 && p.sousId >= 8)

  // eslint-disable-next-line array-callback-return
  const callsFarmInfo = FarmsWithEnd.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'farmInfo'
    }
  })

  const starts = await multicall(sousChefABI, callsFarmInfo)
  const ends = await multicall(sousChefABI, callsFarmInfo)

  const callsFarmWithDepsoitInfo = FarmsWithEndDepositFee.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'farmInfo'
    }
  })

  const startWithDepostiFee = await multicall(sousChefABIDeposit, callsFarmWithDepsoitInfo)
  // const endsWithDepostiFee = await multicall(sousChefABIDeposit, callsFarmWithDepsoitInfo);

  return farms.map((cakePoolConfig, index) => {
    if (cakePoolConfig.sousId === 0) {
      return {
        sousId: cakePoolConfig.sousId,
        startBlock: new BigNumber('0').toJSON(),
        endBlock: new BigNumber('0').toJSON()
      }
      // eslint-disable-next-line no-else-return
    }

    const startBlock = starts[index - 1].startBlock._hex
    const endBlock = ends[index - 1].endBlock._hex
    const poolHarvestIntervall = starts[index - 1].harvestInterval._hex
    const poolwithdrawalFeeBP = starts[index - 1].withdrawalFeeBP
    let depositFee = 0

    depositFee = startWithDepostiFee[index - 1].depositFeeBP

    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
      poolHarvestInterval: new BigNumber(poolHarvestIntervall).toJSON(),
      poolwithdrawalFeeBP: new BigNumber(poolwithdrawalFeeBP).toJSON(),
      pooldepositFeeBP: new BigNumber(depositFee).toJSON()
    }
  })
}

export const fetchFarmsTotalStatking = async (farms: any) => {
  const FarmsWithEnd = farms.filter(p => p.sousId !== 0)

  const nonBnbFarms = FarmsWithEnd.filter(p => p.stakingTokenName !== QuoteToken.BNB)
  const bnbPool = FarmsWithEnd.filter(p => p.stakingTokenName === QuoteToken.BNB)

  const callsNonBnbFarms = nonBnbFarms.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)]
    }
  })

  // const callsBnbFarms = bnbPool.map(poolConfig => {
  //   return {
  //     address: getWbnbAddress(),
  //     name: 'balanceOf',
  //     params: [getAddress(poolConfig.contractAddress)]
  //   }
  // })

  const nonBnbFarmsTotalStaked = await multicall(sousChefABI, callsNonBnbFarms)
  const bnbFarmsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    {
      sousId: 0
    },
    ...nonBnbFarms.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbFarmsTotalStaked[index]).toJSON()
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbFarmsTotalStaked[index]).toJSON()
    }))
  ]
}

export const fetchFarmsLpData = async (farms: any, chainId: any) => {
  const nonBnbFarms = farms.filter(p => p.stakingTokenName !== QuoteToken.BNB)
  const data = await Promise.all(
    nonBnbFarms.map(async farmConfig => {
      const lpAdress = farmConfig.stakingTokenAddress
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAddress,
          name: 'balanceOf',
          params: [lpAdress]
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.tokenAdressInLp,
          name: 'balanceOf',
          params: [lpAdress]
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAdress,
          name: 'balanceOf',
          params: [getAddress(farmConfig.contractAddress)]
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply'
        },
        // Token decimals
        {
          address: farmConfig.tokenAddress,
          name: 'decimals'
        },
        // Token decimals
        {
          address: farmConfig.tokenAdressInLp,
          name: 'decimals'
        },
        {
          address: farmConfig.tokenAddress,
          name: 'name'
        },
        // Quote token name
        {
          address: farmConfig.tokenAdressInLp,
          name: 'name'
        },
        {
          address: farmConfig.tokenAddress,
          name: 'symbol'
        },
        // Quote token name
        {
          address: farmConfig.tokenAdressInLp,
          name: 'symbol'
        }
      ]
      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        tokenName,
        quoteTokenName,
        tokenSymbol,
        quoteTokenSymbol
        // @ts-ignore
      ] = await multicall(erc20, calls)
      // samarth
      const token0 = new Token(
        chainId,
        farmConfig.tokenAddress,
        tokenDecimals,
        tokenSymbol ? tokenSymbol : 'UNKNOWN',
        tokenName ? tokenName : 'Unknown Token'
      )
      const token1 = new Token(
        chainId,
        farmConfig.tokenAdressInLp,
        quoteTokenDecimals,
        quoteTokenSymbol ? quoteTokenSymbol : 'UNKNOWN',
        quoteTokenName ? quoteTokenName : 'Unknown Token'
      )
      const tokens = [token0, token1]
      let rewardPerDay = 0
      let depositFee = 0
      const periodFinish = '0'
      const callsRewards = [
        {
          address: getAddress(farmConfig.contractAddress),
          name: 'poolInfo',
          params: [farmConfig.sousId]
        },
        {
          address: getAddress(farmConfig.contractAddress),
          name: 'totalAllocPoint'
        },
        {
          address: getAddress(farmConfig.contractAddress),
          name: 'MoxiePerBlock'
        }
      ]

      const [info, totalAllocPoint, MoxiePerBlock] = await multicall(sousChefABI, callsRewards)
      const allocPoint = new BigNumber(info.allocPoint._hex)
      depositFee = info.depositFeeBP

      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      rewardPerDay = (parseFloat(MoxiePerBlock.toString()) / 1e18) * parseFloat(poolWeight) * 43200
      // if (farmConfig.isTokenOnly) {
      //   const REALBALANCE = new BigNumber(10000000)
      //   const realbalance = parseFloat(REALBALANCE.toString()) - 2000000
      //  //console.log(REALBALANCE.toString())
      //   tokenAmount = new BigNumber(REALBALANCE.toString())
      //   if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
      //     tokenPriceVsQuote = new BigNumber(1)
      //   } else {
      //     tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
      //   }
      //   lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      // } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      const quoteTokeFirstAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      // }
      const vaultApy = 0
      const availableLimit = 0
      const vaultLimit = 0
      const vaultLaunchPad = 0
      const vaultStakingTime = 0
      const withdrawEnableTimestamp = 0
      // console.log({
      //   quoteTokenAmount: quoteTokenAmount.toJSON(),
      //   tokenAmount: tokenAmount.toString(),
      //   tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toString()
      // })
      return {
        ...farmConfig,
        vaultApy,
        vaultLimit,
        periodFinish,
        availableLimit,
        vaultLaunchPad,
        withdrawEnableTimestamp,
        vaultStakingTime,
        tokens,
        tokenAmount: tokenAmount.toJSON(),
        rewardPerDay: rewardPerDay.toFixed(2),
        depositFee: depositFee,
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        totalDeposited: lpTokenBalanceMC.toString(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        quoteTokeFirstAmount: quoteTokeFirstAmount.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON()
      }
    })
  )
  return data
}
