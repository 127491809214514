// @ts-nocheck

// Array of available nodes to connect to
export const nodes = {
  '80001': 'https://polygon-mumbai.g.alchemy.com/v2/npgkrja2S9UatBgIdLd2Kz6EtHrbvFFs',
  '137': 'https://polygon-mainnet.g.alchemy.com/v2/wL8bPLDoiIsqs5BlQjJJRQnOpUcNvg57',
  '1': 'https://eth-mainnet.g.alchemy.com/v2/bLOUeund0-lmpVJBse9X8IBrZCLHibiK',
  '43114': 'https://api.avax.network/ext/bc/C/rpc',
  '42161': 'https://arb1.arbitrum.io/rpc',
  '10': 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
  '1313161554': 'https://mainnet.aurora.dev'
}

const getNodeUrl = () => {
  let chainId = '137'
  if (window && window.ethereum) {
    chainId = window.ethereum.networkVersion
  }
  if (nodes[chainId] === null || nodes[chainId] === undefined) {
    chainId = process.env.REACT_APP_CHAIN_ID
  }
  return nodes[chainId]
}

export default getNodeUrl
