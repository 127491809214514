/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { ChainId, JSBI, Percent, Token, WETH, SUSHI_ADDRESS } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import quickLogo from '../assets/images/quickswap-logo.svg'
import uniLogo from '../assets/images/uniswap.png'
import suhsiLogo from '../assets/images/sushiswap.png'
import moxieLogo from '../assets/images/MOXIE.png'

// export const ROUTER_ADDRESS_LOCAL =

// export const ROUTER_ADDRESS_LOCAL = {
//   [ChainId.MAINNET]: ROUTER_ADDRESS[ChainId.MAINNET],
//   [ChainId.ROPSTEN]: ROUTER_ADDRESS[ChainId.ROPSTEN],
//   [ChainId.RINKEBY]: ROUTER_ADDRESS[ChainId.RINKEBY],
//   [ChainId.GÖRLI]: ROUTER_ADDRESS[ChainId.GÖRLI],
//   [ChainId.KOVAN]: ROUTER_ADDRESS[ChainId.KOVAN],
//   [ChainId.MATIC]: ROUTER_ADDRESS[ChainId.MATIC],
//   [ChainId.MATIC_TESTNET]: ROUTER_ADDRESS[ChainId.MATIC_TESTNET],
//   [ChainId.FUJI]: ROUTER_ADDRESS[ChainId.FUJI]
// }

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const CHLI = new Token(
  ChainId.MATIC_TESTNET,
  '0x6942432106292215CF2625E249C9aeC0FDAf3872',
  18,
  'CHLI',
  'Chilli Token'
)
export const DAI_MATIC = new Token(
  ChainId.MATIC,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_MATIC = new Token(ChainId.MATIC, '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 6, 'USDC', 'USD//C')
export const USDT_MATIC = new Token(
  ChainId.MATIC,
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  6,
  'USDT',
  'Tether USD'
)

export const MOXIE_MATIC = new Token(ChainId.MATIC, '0x13A5FB6ec4a10C3a25615F2631057277b2bC712C', 18, 'Moxie', 'Moxie')

export const DAI_MATIC_TESTNET = new Token(
  ChainId.MATIC_TESTNET,
  '0x332C7aC34580dfEF553B7726549cEc7015C4B39b',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_MATIC_TESTNET = new Token(
  ChainId.MATIC_TESTNET,
  '0x3861e9F29fcAFF738906c7a3a495583eE7Ca4C18',
  6,
  'USDC',
  'USD//C'
)
export const USDT_MATIC_TESTNET = new Token(
  ChainId.MATIC_TESTNET,
  '0x0aCBd07e458F228d4869066b998a0F55F36537B2',
  6,
  'USDT',
  'Tether USD'
)

export const DAIAVAX = new Token(
  ChainId.AVALANCHE,
  '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDCAVAX = new Token(ChainId.AVALANCHE, '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', 6, 'USDC', 'USD//C')
export const USDTAVAX = new Token(
  ChainId.AVALANCHE,
  '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  6,
  'USDT',
  'Tether USD'
)

export const ETHAVAX = new Token(
  ChainId.AVALANCHE,
  '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
  18,
  'WETH.e',
  'Wrapped Ether'
)

// arbitrum

export const DAI_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  6,
  'USDC',
  'USD//C'
)
export const USDT_ARBITRUM = new Token(
  ChainId.ARBITRUM,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Tether USD'
)

// optimism

export const DAI_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
  6,
  'USDC',
  'USD//C'
)
export const USDT_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
  6,
  'USDT',
  'Tether USD'
)

// aurora mainnet

export const DAI_AURORA = new Token(
  ChainId.AURORA,
  '0xe3520349F477A5F6EB06107066048508498A291b',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC_AURORA = new Token(ChainId.AURORA, '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802', 6, 'USDC', 'USD//C')
export const USDT_AURORA = new Token(
  ChainId.AURORA,
  '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
  6,
  'USDT',
  'Tether USD'
)

export const DAI_ETHEREUM = new Token(
  ChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)

export const USDC_ETHEREUM = new Token(
  ChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const USDT_ETHEREUM = new Token(
  ChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)

// export const CHLI = new Token(ChainId.MATIC, '0x8c7f28BD18ee3BBe766b2036c9E8A8357B07CACa', 18, 'CHLI', 'Chilli Token')
// export const DAI = new Token(ChainId.MATIC, '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', 18, 'DAI', 'Dai Stablecoin')
// export const USDC = new Token(ChainId.MATIC, '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 6, 'USDC', 'USD//C')
// export const USDT = new Token(ChainId.MATIC, '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 6, 'USDT', 'Tether USD')
// export const mWETH = new Token(ChainId.MATIC, '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 18, 'ETH', 'Ether')
// export const ROUTE = new Token(ChainId.MATIC, '0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4', 18, 'ROUTE', 'Route')
// export const OM = new Token(ChainId.MATIC, '0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea', 18, 'OM', 'Mantra Dao')
// export const EASY = new Token(ChainId.MATIC, '0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5', 18, 'EASY', 'Easy')
// export const RAZOR = new Token(ChainId.MATIC, '0xc91c06db0f7bffba61e2a5645cc15686f0a8c828', 18, 'RAZOR', 'Razor')
// export const IGG = new Token(ChainId.MATIC, '0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce', 6, 'IGG', 'IGG')
// export const QUICK = new Token(ChainId.MATIC, '0x831753dd7087cac61ab5644b308642cc1c33dc13', 18, 'QUICK', 'Quick')
// export const COR = new Token(ChainId.MATIC, '0x4fdce518fe527439fe76883e6b51a1c522b61b7c', 18, 'COR', 'Coreto')
// export const FRONT = new Token(ChainId.MATIC, '0xa3ed22eee92a3872709823a6970069e12a4540eb', 18, 'FRONT', 'Frontier')
// export const GLCH = new Token(ChainId.MATIC, '0xbe5cf150e1ff59ca7f2499eaa13bfc40aae70e78', 18, 'GLCH', 'Glitch')
// export const STR = new Token(ChainId.MATIC, '0xa79e0bfc579c709819f4a0e95d4597f03093b011', 18, 'STR', 'Stater')
// export const UNN = new Token(ChainId.MATIC, '0x67480287cb3715d1d9429b38772c71d6e94c16da', 18, 'UNN', 'Union')
// export const NORD = new Token(ChainId.MATIC, '0xf6f85b3f9fd581c2ee717c404f7684486f057f95', 18, 'NORD', 'Nord')
// export const RAGE = new Token(ChainId.MATIC, '0x40ccd55b789fdee8d434915dc2aa6bd938506a92', 18, 'RAGE', 'Rage')

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 14
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const UNI_ADDRESS = '0x710876fB8936066ce9f30F9acF4806AC06Ec1DcF'

export const UNI = SUSHI_ADDRESS

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}
// @ts-ignore
const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.MATIC]: [WETH[ChainId.MATIC]],
  [ChainId.MATIC_TESTNET]: [WETH[ChainId.MATIC_TESTNET]],
  [ChainId.FUJI]: [WETH[ChainId.FUJI]],
  [ChainId.AVALANCHE]: [WETH[ChainId.AVALANCHE]],
  [ChainId.ARBITRUM]: [WETH[ChainId.ARBITRUM]],
  [ChainId.OPTIMISM]: [WETH[ChainId.OPTIMISM]],
  [ChainId.AURORA]: [WETH[ChainId.AURORA]]
}
// , DAI, USDC, USDT, mWETH, CHLI
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], DAI_MATIC, USDC_MATIC, USDT_MATIC, MOXIE_MATIC],
  [ChainId.MATIC_TESTNET]: [
    ...WETH_ONLY[ChainId.MATIC_TESTNET],
    DAI_MATIC_TESTNET,
    USDC_MATIC_TESTNET,
    USDT_MATIC_TESTNET
  ],
  [ChainId.AVALANCHE]: [...WETH_ONLY[ChainId.AVALANCHE], DAIAVAX, USDTAVAX, USDCAVAX, ETHAVAX],
  [ChainId.ARBITRUM]: [...WETH_ONLY[ChainId.ARBITRUM], DAI_ARBITRUM, USDT_ARBITRUM, USDC_ARBITRUM],
  [ChainId.AURORA]: [...WETH_ONLY[ChainId.AURORA], DAI_AURORA, USDT_AURORA, USDC_AURORA],
  [ChainId.OPTIMISM]: [...WETH_ONLY[ChainId.OPTIMISM], DAI_OPTIMISM, USDT_OPTIMISM, USDC_OPTIMISM],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI_ETHEREUM, USDT_ETHEREUM, USDC_ETHEREUM]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MATIC]: {},
  [ChainId.MATIC_TESTNET]: {},
  [ChainId.AVALANCHE]: {},
  [ChainId.ARBITRUM]: {},
  [ChainId.AURORA]: {},
  [ChainId.OPTIMISM]: {},
  [ChainId.MAINNET]: {}
}
// , DAI, USDC, USDT, mWETH, CHLI
// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], DAI_MATIC, USDC_MATIC, USDT_MATIC],
  [ChainId.AVALANCHE]: [...WETH_ONLY[ChainId.AVALANCHE], DAIAVAX, USDTAVAX, USDCAVAX, ETHAVAX],
  [ChainId.MATIC_TESTNET]: [
    ...WETH_ONLY[ChainId.MATIC_TESTNET],
    DAI_MATIC_TESTNET,
    USDC_MATIC_TESTNET,
    USDT_MATIC_TESTNET
  ],
  [ChainId.AVALANCHE]: [...WETH_ONLY[ChainId.AVALANCHE], DAIAVAX, USDTAVAX, USDCAVAX, ETHAVAX],
  [ChainId.ARBITRUM]: [...WETH_ONLY[ChainId.ARBITRUM], DAI_ARBITRUM, USDT_ARBITRUM, USDC_ARBITRUM],
  [ChainId.AURORA]: [...WETH_ONLY[ChainId.AURORA], DAI_AURORA, USDT_AURORA, USDC_AURORA],
  [ChainId.OPTIMISM]: [...WETH_ONLY[ChainId.OPTIMISM], DAI_OPTIMISM, USDT_OPTIMISM, USDC_OPTIMISM],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI_ETHEREUM, USDT_ETHEREUM, USDC_ETHEREUM]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MATIC]: [...WETH_ONLY[ChainId.MATIC], DAI_MATIC, USDC_MATIC, USDT_MATIC, MOXIE_MATIC],
  [ChainId.MATIC_TESTNET]: [
    ...WETH_ONLY[ChainId.MATIC_TESTNET],
    DAI_MATIC_TESTNET,
    USDC_MATIC_TESTNET,
    USDT_MATIC_TESTNET
  ],
  [ChainId.AVALANCHE]: [...WETH_ONLY[ChainId.AVALANCHE], DAIAVAX, USDTAVAX, USDCAVAX, ETHAVAX],
  [ChainId.ARBITRUM]: [...WETH_ONLY[ChainId.ARBITRUM], DAI_ARBITRUM, USDT_ARBITRUM, USDC_ARBITRUM],
  [ChainId.AURORA]: [...WETH_ONLY[ChainId.AURORA], DAI_AURORA, USDT_AURORA, USDC_AURORA],
  [ChainId.OPTIMISM]: [...WETH_ONLY[ChainId.OPTIMISM], DAI_OPTIMISM, USDT_OPTIMISM, USDC_OPTIMISM],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI_ETHEREUM, USDT_ETHEREUM, USDC_ETHEREUM]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MATIC]: [
    [WETH[ChainId.MATIC], USDT_MATIC],
    [WETH[ChainId.MATIC], DAI_MATIC],
    [WETH[ChainId.MATIC], MOXIE_MATIC]
  ],
  [ChainId.MAINNET]: [
    [WETH[ChainId.MAINNET], USDT_ETHEREUM],
    [WETH[ChainId.MAINNET], DAI_ETHEREUM],
    [WETH[ChainId.MAINNET], USDC_ETHEREUM]
  ],
  [ChainId.AVALANCHE]: [
    // [ETHAVAX, USDTAVAX],
    // [ETHAVAX, DAIAVAX],
    // [ETHAVAX, USDCAVAX],
    // [ETHAVAX, ETHAVAX],
    [WETH[ChainId.AVALANCHE], USDTAVAX],
    [WETH[ChainId.AVALANCHE], DAIAVAX],
    [WETH[ChainId.AVALANCHE], USDCAVAX],
    [WETH[ChainId.AVALANCHE], ETHAVAX]
  ],
  [ChainId.MATIC_TESTNET]: [
    [WETH[ChainId.MATIC_TESTNET], USDT_MATIC_TESTNET],
    [WETH[ChainId.MATIC_TESTNET], DAI_MATIC_TESTNET],
    [WETH[ChainId.MATIC_TESTNET], USDC_MATIC_TESTNET]
  ],
  [ChainId.ARBITRUM]: [
    [WETH[ChainId.ARBITRUM], USDT_ARBITRUM],
    [WETH[ChainId.ARBITRUM], DAI_ARBITRUM],
    [WETH[ChainId.ARBITRUM], USDC_ARBITRUM]
  ],
  [ChainId.OPTIMISM]: [
    [WETH[ChainId.OPTIMISM], USDT_OPTIMISM],
    [WETH[ChainId.OPTIMISM], DAI_OPTIMISM],
    [WETH[ChainId.OPTIMISM], USDC_OPTIMISM]
  ],
  [ChainId.AURORA]: [
    [WETH[ChainId.AURORA], USDT_AURORA],
    [WETH[ChainId.AURORA], DAI_AURORA],
    [WETH[ChainId.AURORA], USDC_AURORA]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const NETWORK_LABELS_ROOT: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.MATIC_TESTNET]: 'Matic Testnet',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.FUJI]: 'AVAX Testnet',
  [ChainId.AVALANCHE]: 'AVAX Mainnet',
  [ChainId.ARBITRUM]: 'Arbitrum',
  [ChainId.OPTIMISM]: 'Optimism',
  [ChainId.AURORA]: 'Aurora'
}

export const CHAIN_IDS_TO_NAMES_FORSCAN: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.ROPSTEN]: 'ropsten',
  [ChainId.RINKEBY]: 'rinkeby',
  [ChainId.KOVAN]: 'kovan',
  [ChainId.MATIC]: 'polygon',
  [ChainId.MATIC_TESTNET]: 'polygon_mumbai',
  [ChainId.ARBITRUM]: 'arbitrum',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.AURORA]: 'aurora',
  [ChainId.AVALANCHE]: 'avalanch'
}

export const networks = [
  {
    title: 'Ethereum Mainnet',
    chainId: '1',
    imgSrc: 'https://etherscan.io/images/svg/brands/ethereum-1.svg',
    rpcUrl: 'https://mainnet.infura.io/v3/63273290f2b64f1d956e2a607d17b196',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://etherscan.io']
  },
  {
    title: 'polygon Mainnet',
    chainId: '137',
    imgSrc: 'https://polygonscan.com/images/svg/brands/polygon.svg',
    rpcUrl: 'https://polygon-mainnet.g.alchemy.com/v2/wL8bPLDoiIsqs5BlQjJJRQnOpUcNvg57',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
  },
  {
    title: 'polygon Testnet',
    chainId: '80001',
    imgSrc: 'https://polygonscan.com/images/svg/brands/polygon.svg',
    rpcUrl: 'https://polygon-mumbai.g.alchemy.com/v2/npgkrja2S9UatBgIdLd2Kz6EtHrbvFFs',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
  },
  {
    title: 'Avax Mainnet',
    chainId: '43114',
    imgSrc: '	https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    blockExplorerUrls: ['https://snowtrace.io']
  },
  {
    title: 'Arbitrum One',
    chainId: '42161',
    imgSrc: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png',
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://arbiscan.io']
  },
  {
    title: 'Optimism',
    chainId: '10',
    imgSrc: 'https://app.uniswap.org/static/media/optimistic_ethereum.34412af2.svg',
    rpcUrl: 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://optimistic.etherscan.io']
  },
  {
    title: 'Aurora Mainnet',
    chainId: '1313161554',
    imgSrc: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14803.png',
    rpcUrl: 'https://mainnet.aurora.dev',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://aurorascan.dev']
  }
]
export const poweredBy = {
  [ChainId.MAINNET]: {
    title: 'Uniswap',
    image: uniLogo
  },
  [ChainId.ROPSTEN]: {
    title: 'Uniswap',
    image: uniLogo
  },
  [ChainId.RINKEBY]: {
    title: 'Uniswap',
    image: uniLogo
  },
  [ChainId.KOVAN]: {
    title: 'Uniswap',
    image: uniLogo
  },
  [ChainId.MATIC]: {
    title: 'Quickswap',
    image: quickLogo
  },
  [ChainId.MATIC_TESTNET]: {
    title: 'Quickswap',
    image: quickLogo
  },
  [ChainId.ARBITRUM]: {
    title: 'Sushiswap',
    image: suhsiLogo
  },
  [ChainId.OPTIMISM]: {
    title: 'Moxieswap',
    image: moxieLogo
  },
  [ChainId.AURORA]: {
    title: 'Moxieswap',
    image: moxieLogo
  },
  [ChainId.AVALANCHE]: {
    title: 'Sushiswap',
    image: suhsiLogo
  }
}
