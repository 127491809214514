// @ts-nocheck
import React, { useState, useCallback } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { TokenAmount, Pair } from '@uniswap/sdk'
import { getAddress } from 'utils/addressHelpers'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { useCurrency } from '../../hooks/Tokens'
import { ButtonError } from '../Button'
// import { StakingInfo } from '../../state/stake/hooks'
import { useStakingContract } from '../../hooks/useContract'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { SubmittedView, LoadingView } from '../ModalViews'
import useWeb3 from 'hooks/useWeb3'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
// import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'
import { Pool } from '../../state/types'
import { BigNumber } from 'bignumber.js'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
})
const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: Pool
  userLiquidityUnstaked: string
}

export default function UnstakingModal({ isOpen, onDismiss, poolInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { account } = useActiveWeb3React()
  const web3 = useWeb3()
  const currency = useCurrency(poolInfo.stakingTokenAddress)
  const [typedValue, setTypedValue] = useState('')
  const dummyPair = new Pair(new TokenAmount(poolInfo.tokens[0], '0'), new TokenAmount(poolInfo.tokens[1], '0'))
  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  let depositedLiquidity
  if (poolInfo.userData?.stakedBalance) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    depositedLiquidity = new BigNumber(poolInfo.userData?.stakedBalance).div(new BigNumber(10).pow(18)).toString()
  }
  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useStakingContract(getAddress(poolInfo.contractAddress))
  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    // setSignatureData(null)
    setTypedValue(typedValue)
  }, [])
  const atMaxAmount = Boolean(userLiquidityUnstaked === typedValue)
  const handleMax = useCallback(() => {
    depositedLiquidity && onUserInput(depositedLiquidity)
  }, [depositedLiquidity, onUserInput])
  async function onWithdraw() {
    const valInWei = web3.utils.toWei(typedValue)
    if (stakingContract && poolInfo?.userData?.stakingTokenBalance) {
      setAttempting(true)
      await stakingContract
        .withdraw(poolInfo.sousId, valInWei, { gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          //console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!poolInfo?.userData?.stakingTokenBalance) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {depositedLiquidity && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body>Deposited liquidity:</TYPE.body>
              <TYPE.body fontWeight={600} fontSize={36}>
                {/* {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />} */}
                {depositedLiquidity}
              </TYPE.body>
            </AutoColumn>
          )}
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={currency}
            pair={dummyPair}
            label={''}
            hideBalance
            disableCurrencySelect={true}
            customBalanceText={'Available to Withdraw: '}
            id="stake-liquidity-token"
          />
          {false && poolInfo.userData && poolInfo.userData.stakedBalance && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {/* {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />} */}
                {poolInfo.userData && poolInfo.userData.stakedBalance
                  ? parseFloat(poolInfo?.userData?.stakedBalance.toString()) / 1e18
                  : '0'}
              </TYPE.body>
              <TYPE.body>Unclaimed Chilli</TYPE.body>
            </AutoColumn>
          )}
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your MOXIE is claimed and your liquidity is removed from the mining pool.
          </TYPE.subHeader>
          <ButtonError
            disabled={!!error}
            error={!!error && !!poolInfo?.userData?.stakingTokenBalance}
            onClick={onWithdraw}
          >
            {error ?? 'Withdraw'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {typedValue} MOXIE-Lp</TYPE.body>
            {/* <TYPE.body fontSize={20}>
              Claiming{' '}
              {poolInfo.userData && poolInfo.userData.stakedBalance
                ? parseFloat(poolInfo?.userData?.stakedBalance.toString()) / 1e18
                : '0'}
              Chilli
            </TYPE.body> */}
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdraw Moxie-LP</TYPE.body>
            <TYPE.body fontSize={20}>Claimed Moxie</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
