/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import { Token } from '@uniswap/sdk'
import vaults from 'constants/config/vaults'
import sousChefABI from 'constants/abis/sousChef.json'
import vaultABI from 'constants/abis/vault.json'
import sousChefABIDeposit from 'constants/abis/sousChefDepost.json'
import wbnbABI from 'constants/abis/weth.json'
import { QuoteToken } from 'constants/config/types'
import erc20 from 'constants/abis/erc20.json'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { getWeb3NoAccount } from 'utils/web3'

// @ts-ignore
const poolsConfig = vaults

const web3 = getWeb3NoAccount()
export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter(p => p.sousId !== 0)

  const poolsWithEndDepositFee = poolsConfig.filter(p => p.sousId !== 0 && p.sousId >= 8)

  // eslint-disable-next-line array-callback-return
  const callsFarmInfo = poolsWithEnd.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'farmInfo'
    }
  })

  const starts = await multicall(sousChefABI, callsFarmInfo)
  const ends = await multicall(sousChefABI, callsFarmInfo)

  const callsFarmWithDepsoitInfo = poolsWithEndDepositFee.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'farmInfo'
    }
  })

  const startWithDepostiFee = await multicall(sousChefABIDeposit, callsFarmWithDepsoitInfo)
  // const endsWithDepostiFee = await multicall(sousChefABIDeposit, callsFarmWithDepsoitInfo);

  return poolsConfig.map((cakePoolConfig, index) => {
    if (cakePoolConfig.sousId === 0) {
      return {
        sousId: cakePoolConfig.sousId,
        startBlock: new BigNumber('0').toJSON(),
        endBlock: new BigNumber('0').toJSON()
      }
      // eslint-disable-next-line no-else-return
    }

    const startBlock = starts[index - 1].startBlock._hex
    const endBlock = ends[index - 1].endBlock._hex
    const poolHarvestIntervall = starts[index - 1].harvestInterval._hex
    const poolwithdrawalFeeBP = starts[index - 1].withdrawalFeeBP
    let depositFee = 0

    if (cakePoolConfig.sousId >= 8) {
      depositFee = startWithDepostiFee[index - 1 - 7].depositFeeBP
    }

    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
      poolHarvestInterval: new BigNumber(poolHarvestIntervall).toJSON(),
      poolwithdrawalFeeBP: new BigNumber(poolwithdrawalFeeBP).toJSON(),
      pooldepositFeeBP: new BigNumber(depositFee).toJSON()
    }
  })
}

export const fetchPoolsTotalStatking = async () => {
  const poolsWithEnd = poolsConfig.filter(p => p.sousId !== 0)

  const nonBnbPools = poolsWithEnd.filter(p => p.stakingTokenName !== QuoteToken.BNB)
  const bnbPool = poolsWithEnd.filter(p => p.stakingTokenName === QuoteToken.BNB)

  const callsNonBnbPools = nonBnbPools.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)]
    }
  })

  const callsBnbPools = bnbPool.map(poolConfig => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)]
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(sousChefABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    {
      sousId: 0
    },
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON()
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON()
    }))
  ]
}

export const fetchPoolsLpData = async (pools: any, chainId: any) => {
  const nonBnbPools = pools.filter(p => p.stakingTokenName !== QuoteToken.BNB)

  const data = await Promise.all(
    nonBnbPools.map(async farmConfig => {
      const lpAdress = farmConfig.stakingTokenAddress
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAdressInLp,
          name: 'balanceOf',
          params: [lpAdress]
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.tokenAdressInLp,
          name: 'balanceOf',
          params: [lpAdress]
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAdress,
          name: 'balanceOf',
          params: [getAddress(farmConfig.contractAddress)]
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply'
        },
        // Token decimals
        {
          address: farmConfig.tokenAddress,
          name: 'decimals'
        },
        // Token decimals
        {
          address: farmConfig.tokenAdressInLp,
          name: 'decimals'
        },
        {
          address: farmConfig.tokenAddress,
          name: 'name'
        },
        // Quote token name
        {
          address: farmConfig.tokenAdressInLp,
          name: 'name'
        },
        {
          address: farmConfig.tokenAddress,
          name: 'symbol'
        },
        // Quote token name
        {
          address: farmConfig.tokenAdressInLp,
          name: 'symbol'
        }
      ]
      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        tokenName,
        quoteTokenName,
        tokenSymbol,
        quoteTokenSymbol
        // @ts-ignore
      ] = await multicall(erc20, calls)
      // console.log('in poolls', {
      //   tokenName: tokenName.toString(),
      //   quoteTokenName: quoteTokenName.toString(),
      //   tokenSymbol: tokenSymbol.toString(),
      //   quoteTokenSymbol: quoteTokenSymbol.toString(),
      //   tokenDecimals: tokenDecimals.toString(),
      //   quoteTokenDecimals: quoteTokenDecimals.toString()
      // })
      const token0 = new Token(
        chainId,
        farmConfig.tokenAddress,
        tokenDecimals,
        tokenSymbol ? tokenSymbol : 'UNKNOWN',
        tokenName ? tokenName : 'Unknown Token'
      )
      const token1 = new Token(
        chainId,
        farmConfig.tokenAdressInLp,
        quoteTokenDecimals,
        quoteTokenSymbol ? quoteTokenSymbol : 'UNKNOWN',
        quoteTokenName ? quoteTokenName : 'Unknown Token'
      )
      const tokens = [token0, token1]
      const rewardPerDay = 0
      const periodFinish = '0'
      // if (farmConfig.isTokenOnly) {
      //   const REALBALANCE = new BigNumber(10000000)
      //   const realbalance = parseFloat(REALBALANCE.toString()) - 2000000
      //  //console.log(REALBALANCE.toString())
      //   tokenAmount = new BigNumber(REALBALANCE.toString())
      //   if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
      //     tokenPriceVsQuote = new BigNumber(1)
      //   } else {
      //     tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
      //   }
      //   lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      // } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      const quoteTokeFirstAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      // }
      let vaultApy = 0
      // const availableLimit = 0
      // const vaultLimit = 0
      let vaultTotalDeposited = 0

      let vaultPoolSize = 0
      // const vaultLaunchPad = 0
      // const vaultStakingTime = 0
      // let withdrawEnableTimestamp = 0
      const callVaults = [
        {
          address: getAddress(farmConfig.contractAddress),
          name: 'totalTokenStaked'
        },
        // {
        //   address: getAddress(farmConfig.contractAddress),
        //   name: 'totalRewardToken'
        // },
        {
          address: getAddress(farmConfig.contractAddress),
          name: 'rewardPercent'
        },
        {
          address: getAddress(farmConfig.contractAddress),
          name: 'poolSize'
        }
      ]
      const [totalDeposited, rewardPercent, poolSize] = await multicall(vaultABI, callVaults)
      // const poolSize = 0
      // const [totalDeposited, rewardPercent] = await multicall(vaultABI, callVaults)
      vaultApy = parseFloat(rewardPercent.toString()) / 100
      // vaultLimit = parseFloat(web3.utils.fromWei(vaultLockLimit.toString(), 'ether'))
      vaultTotalDeposited = parseFloat(web3.utils.fromWei(totalDeposited.toString(), 'ether'))

      vaultPoolSize = parseFloat(web3.utils.fromWei(poolSize.toString(), 'ether'))
      // availableLimit = parseFloat(vaultLimit) - parseFloat(vaultTotalDeposited)
      // vaultLaunchPad = launchDate.toString()
      // vaultStakingTime = parseFloat(stakingTime.toString())
      // withdrawEnableTimestamp = parseFloat(launchDate.toString()) + parseFloat(stakingTime.toString()) * 24 * 60 * 60
      // const vaulttotalRewardToken = new BigNumber(totalRewardToken).dividedBy(
      //   10 ** parseFloat(farmConfig.stakingTokenDecimals)
      // )
      //console.log({ vaultTotalDeposited, vaultApy })
      return {
        ...farmConfig,
        vaultApy,
        // vaultLimit,
        periodFinish,
        vaultTotalDeposited,
        vaultPoolSize,
        // vaulttotalRewardToken,
        // availableLimit,
        // vaultLaunchPad,
        // withdrawEnableTimestamp,
        // vaultStakingTime,
        tokens,
        tokenAmount: tokenAmount.toJSON(),
        rewardPerDay: rewardPerDay.toFixed(2),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        totalDeposited: lpTokenBalanceMC.toString(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        quoteTokeFirstAmount: quoteTokeFirstAmount.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        rewardPercent: new BigNumber(rewardPercent).dividedBy(100)
      }
    })
  )

  return data
}
