/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAddress } from 'utils/addressHelpers'
import useRefresh from 'hooks/useRefresh'
import CoinGecko from 'coingecko-api'
import { fetchPoolsPublicDataAsync, fetchFarmsPublicDataAsync } from './actions'
import { State, Pool } from './types'
import BigNumber from 'bignumber.js'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])

  // useEffect(() => {
  //   const web3 = getWeb3NoAccount()
  //   const interval = setInterval(async () => {
  //     const blockNumber = await web3.eth.getBlockNumber()
  //     dispatch(setBlock(blockNumber))
  //   }, 30000)

  //   return () => clearInterval(interval)
  // }, [dispatch])
}

export const fetchPrice = async (crypto: string) => {
  const CoinGeckoClient = new CoinGecko()
  const result = await CoinGeckoClient.coins.fetch(crypto.toLocaleLowerCase(), {})
  const res = new BigNumber(result.data?.market_data?.current_price?.usd)
  return res
}

export const usePools = (): Pool[] => {
  const pools = useSelector((state: State) => state.pools.data)
  return pools
}
export const useFarms = (): Pool[] => {
  const pools = useSelector((state: State) => state.farms.data)
  return pools
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find(f => f.sousId === pid))
  return farm
}

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 0 // USD-MATIC LP, BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  return farm?.tokenPriceVsQuote ? new BigNumber(1).div(farm?.tokenPriceVsQuote) : ZERO
  // return new BigNumber(10);
}

export const usePoolFromContractAddress = (address: string): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find(p => getAddress(p.contractAddress) === address))
  // @ts-ignore
  return pool
}
