// @ts-nocheck

import React, { useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { Pool } from '../../state/types'
// import { AutoColumn } from '../Column'
// import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { ButtonPrimary, ButtonOutlined } from '../../components/Button'
// import { ETHER, JSBI, TokenAmount } from '@uniswap/sdk'
// import { ButtonPrimary } from '../Button'
// import { useColor } from '../../hooks/useColor'
// import { currencyId } from '../../utils/currencyId'
// import { Break, CardNoise, CardBGImage } from './styled'
import { usePriceBnbBusd } from 'state/hooks'
import { Countdown } from '../../pages/Earn/Countdown'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress'

import StakingModalVault from '../../components/earn/StakingModalVaultNew'
import UnstakingModalVault from '../../components/earn/UnstakingModalVaultNew'
import ClaimRewardModalVault from '../../components/earn/ClaimRewardModalVault'
import UnstakingModal from '../../components/earn/UnstakingModaNew'
import StakingModalNew from '../../components/earn/StakingModalNew'
import ClaimRewardModal from '../../components/earn/ClaimRewardModalNew'
import verifiedIcon from '../../assets/images/verified.png'
import { Box, Typography } from '@material-ui/core'

const CardCompoent = styled.div`
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  background: ${({ theme }) => theme.bg1};
`
const ImgContainer = styled.img`
  border-radius: 24px;
  width: 40px;
  box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
  background-color: ${({ theme }) => theme.white};
`

const ImgContainerAlt = styled.img`
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
  background-color: ${({ theme }) => theme.white};
  width: 40px;
  margin: 45px 15px 0px -20px;
`
const TextRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`
const Hr = styled.div`
  width: 100%;
  margin: 15px 0px;
  opacity: 0.5;
  border-bottom: 1px dotted ${({ theme }) => theme.primary1};
`

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default function FarmCard({ pool }: { pool: Pool }) {
  const {
    stakingTokenName,
    tokenName,
    // contractAddress,
    // userData,
    quoteTokenSymbol,
    rewardPerDay,
    depositFee,
    isVault,
    // vaultLimit,
    vaultTotalDeposited,
    vaultPoolSize,
    // vaultLaunchPad,
    // vaultStakingTime,
    lpTotalInQuoteToken,
    isTokenOnly
  } = pool
  const [showStakingModalVault, setShowStakingModalVault] = useState(false)
  const [showUnstakingModalVault, setShowUnstakingModalVault] = useState(false)
  const [showClaimRewardModalVault, setShowClaimRewardModalVault] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const moxiePrice = usePriceBnbBusd()
  const [showMore, setShowMore] = useState(false)
  const totalValue: BigNumber = useMemo(() => {
    if (!lpTotalInQuoteToken) {
      return null
    }
    return moxiePrice ? new BigNumber(moxiePrice).times(lpTotalInQuoteToken) : 0
  }, [moxiePrice, lpTotalInQuoteToken])
  let apy =
    rewardPerDay && moxiePrice && totalValue
      ? new BigNumber(rewardPerDay)
          .multipliedBy(new BigNumber(365).multipliedBy(new BigNumber(moxiePrice)))
          .dividedBy(new BigNumber(totalValue))
      : Number(70)
  if (isVault && pool && pool.rewardPercent) {
    const rewardPercent = new BigNumber(pool.rewardPercent)
    const rewardPerDay = parseFloat(parseFloat(rewardPercent) / parseFloat(pool.lockupPeriod))
    apy = new BigNumber(rewardPerDay).multipliedBy(365)
  }
  let earnedAmount = '0'
  if (isVault) {
    if (pool && pool.rewardPercent && pool.userData && pool.userData.stakedBalance) {
      const userLiquidity = new BigNumber(pool?.userData?.stakedBalance).dividedBy(new BigNumber(10).pow(18))
      earnedAmount = (parseFloat(userLiquidity) * parseFloat(pool.rewardPercent.toString())) / 100
    }
  } else {
    earnedAmount =
      pool.userData && pool.userData.earnedInfo ? parseFloat(pool?.userData?.earnedInfo.toString()) / 1e18 : '0'
    // const totalEarned = parseFloat(pool?.userData?.stakedBalance.toString()) / 1e18
    // earnedAmount = parseFloat(pool?.userData?.stakedBalance.toString()) / 1e18 - totalEarned / 2
  }
  const userLiquidityUnstaked =
    pool.userData && pool.userData.stakedBalance ? parseFloat(pool?.userData?.stakedBalance.toString()) / 1e18 : '0'
  let stakedAmount = '0'
  if (pool?.userData && pool?.userData.stakedBalance) {
    stakedAmount = new BigNumber(pool?.userData?.stakedBalance.toString()).dividedBy(10 ** 18).toString()
  }
  const handleClaim = () => {
    if (isVault) {
      setShowClaimRewardModalVault(!showClaimRewardModalVault)
    } else {
      setShowClaimRewardModal(!showClaimRewardModal)
    }
  }
  const handleDeposit = () => {
    if (isVault) {
      setShowStakingModalVault(!showStakingModalVault)
    } else {
      setShowStakingModal(!showStakingModal)
    }
  }
  const handleWithdraw = () => {
    if (isVault) {
      setShowUnstakingModalVault(!showUnstakingModalVault)
    } else {
      setShowUnstakingModal(!showUnstakingModal)
    }
  }
  return (
    <CardCompoent className="GradientBorder">
      {!isVault && pool.tokens && (
        <StakingModalNew isOpen={showStakingModal} onDismiss={() => setShowStakingModal(false)} poolInfo={pool} />
      )}
      {!isVault && pool.tokens && (
        <UnstakingModal isOpen={showUnstakingModal} onDismiss={() => setShowUnstakingModal(false)} poolInfo={pool} />
      )}
      {showClaimRewardModal && (
        <ClaimRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          poolInfo={pool}
        />
      )}
      {showStakingModalVault && (
        <StakingModalVault
          isOpen={showStakingModalVault}
          onDismiss={() => setShowStakingModalVault(false)}
          poolInfo={pool}
          userLiquidityUnstaked={userLiquidityUnstaked}
        />
      )}
      {showUnstakingModalVault && (
        <UnstakingModalVault
          isOpen={showUnstakingModalVault}
          onDismiss={() => setShowUnstakingModalVault(false)}
          poolInfo={pool}
        />
      )}
      {showClaimRewardModalVault && (
        <ClaimRewardModalVault
          isOpen={showClaimRewardModalVault}
          onDismiss={() => setShowClaimRewardModalVault(false)}
          vaultInfo={pool}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '15px',
          gap: '4px'
        }}
      >
        {!isTokenOnly ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImgContainer src={`/images/tokens/${stakingTokenName?.toLocaleLowerCase()}.png`} alt={stakingTokenName} />
            <ImgContainerAlt
              src={`/images/tokens/${quoteTokenSymbol?.toLocaleLowerCase()}.png`}
              alt={quoteTokenSymbol}
            />
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImgContainer src={`/images/tokens/${stakingTokenName?.toLocaleLowerCase()}.png`} alt={stakingTokenName} />
          </div>
        )}
        <div
          style={{
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'right',
            marginBottom: '15px'
          }}
        >
          <TYPE.black fontWeight={600} fontSize={16} style={{}}>
            {tokenName}
          </TYPE.black>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                border: '2px solid #4caf4f',
                borderRadius: '25px',
                padding: '2px 8px',
                display: 'flex',
                marginTop: '5px',
                marginRight: '5px',
                alignItems: 'center',
                color: '#4caf4f'
              }}
            >
              <img src={verifiedIcon} style={{ height: '22px', width: '22px' }} />
              &nbsp;Verified
            </div>
            <div
              style={{
                border: '2px solid #fdcd06',
                borderRadius: '25px',
                padding: '2px 8px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                color: '#fdcd06'
              }}
            >
              <i className="fas fa-rocket"></i>&nbsp;Boosted
            </div>
          </div>
        </div>
      </div>

      {depositFee && depositFee !== 0 && (
        <div style={{ marginBottom: '15px' }}>
          <TextRow>
            <TYPE.black> Deposit Fee: </TYPE.black>
            <TYPE.main color="primary1">{depositFee / 100} %</TYPE.main>
          </TextRow>
        </div>
      )}

      <div style={{ marginBottom: '15px' }}>
        <TextRow>
          <TYPE.black> APR </TYPE.black>
          <TYPE.main color="primary1">
            <i className="fas fa-rocket"></i> Up to&nbsp;
            {isVault
              ? `${apy.toFixed(3)} %`
              : isTokenOnly
              ? `395`
              : parseFloat(apy?.toString()) > 0 && `${parseFloat(apy?.toString())?.toFixed(2)} %`}
          </TYPE.main>
        </TextRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <TextRow>
          <div>
            <TYPE.black mb="5px"> Your Rewards </TYPE.black>
            <TYPE.main color="primary1" fontSize={18}>
              {parseFloat(earnedAmount).toFixed(2)} MOXIE
            </TYPE.main>
          </div>
          {!isVault && (
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              width="160px"
              disabled={parseFloat(earnedAmount.toString()) <= 0}
              onClick={handleClaim}
            >
              Claim
            </ButtonPrimary>
          )}
        </TextRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <TextRow>
          <TYPE.black mb="5px"> Your Deposits </TYPE.black>
          <TYPE.main color="primary1">
            {parseFloat(stakedAmount).toFixed(3)}
            {isVault ? ` ${pool.stakingTokenName}` : ` ${pool.tokenName}`}
          </TYPE.main>
        </TextRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <TextRow>
          <TYPE.black mb="5px"> {isVault ? ` ${pool.stakingTokenName}` : ` ${pool.tokenName}`} Balance </TYPE.black>
          <TYPE.main color="primary1">
            {pool.userData?.stakingTokenBalance
              ? new BigNumber(pool.userData?.stakingTokenBalance.toString())
                  .dividedBy(10 ** parseFloat(pool.tokenDecimals))
                  .toFixed(3)
              : '0'}
          </TYPE.main>
        </TextRow>
      </div>
      {isVault && vaultTotalDeposited && vaultPoolSize && (
        <div style={{ marginBottom: '15px' }}>
          <TextRow>
            {/* <TYPE.black mb="5px"> {isVault ? ` ${pool.stakingTokenName}` : ` ${pool.tokenName}`} Balance </TYPE.black>
          <TYPE.main color="primary1">
            {pool.userData?.stakingTokenBalance
              ? new BigNumber(pool.userData?.stakingTokenBalance.toString())
                  .dividedBy(10 ** parseFloat(pool.tokenDecimals))
                  .toFixed(3)
              : '0'}
          </TYPE.main> */}
          </TextRow>
          <LinearProgressWithLabel value={(parseFloat(vaultTotalDeposited) * 100) / parseFloat(vaultPoolSize)} />
        </div>
      )}

      <div style={{ marginBottom: '15px' }}>
        <TextRow>
          {pool.userData ? (
            <ButtonPrimary
              padding="8px"
              borderRadius="8px"
              width="100%"
              marginRight={'15px'}
              disabled={
                Date.now() / 1000 < parseInt(pool.userData.unlockTime)
                  ? true
                  : parseFloat(
                      pool.userData?.stakingTokenBalance ? pool.userData?.stakingTokenBalance.toString() : '0'
                    ) <= 0
              }
              onClick={handleDeposit}
            >
              Deposit
            </ButtonPrimary>
          ) : (
            <ButtonPrimary padding="8px" borderRadius="8px" width="100%" marginRight={'15px'} disabled={true}>
              Deposit
            </ButtonPrimary>
          )}
          {pool.userData && isVault && (
            <ButtonOutlined
              padding="8px"
              borderRadius="8px"
              width="100%"
              disabled={parseFloat(stakedAmount) <= 0 || Date.now() / 1000 < parseInt(pool.userData.unlockTime)}
              onClick={handleWithdraw}
            >
              Withdraw
            </ButtonOutlined>
          )}
          {isVault === false && (
            <ButtonOutlined
              padding="8px"
              borderRadius="8px"
              width="100%"
              disabled={parseFloat(stakedAmount) <= 0}
              onClick={handleWithdraw}
            >
              Withdraw
            </ButtonOutlined>
          )}
        </TextRow>
      </div>
      <Hr />

      <TextRow>
        <TYPE.main
          color="primary1"
          style={{ marginLeft: '125px', cursor: 'pointer' }}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Hide' : 'Details'} &nbsp;
          {showMore ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
        </TYPE.main>
      </TextRow>
      {showMore ? (
        <div>
          {isVault ? (
            <div style={{ marginBottom: '15px' }}>
              {pool.userData &&
              pool.userData.unlockTime &&
              parseFloat(pool.userData.unlockTime) > Math.floor(Date.now() / 1000) ? (
                <TextRow>
                  <TYPE.black> Maturity In </TYPE.black>
                  <TYPE.black>
                    <Countdown exactEnd={new Date(parseFloat(pool.userData.unlockTime * 1000))} />
                  </TYPE.black>
                </TextRow>
              ) : (
                <></>
              )}
              {pool.lockupPeriod && (
                <TextRow>
                  <TYPE.black> Lockup Period </TYPE.black>
                  <TYPE.black>{pool.lockupPeriod} days</TYPE.black>
                </TextRow>
              )}
            </div>
          ) : (
            <div style={{ marginBottom: '15px' }}>
              <TextRow>
                <TYPE.black> {stakingTokenName} Pool rate </TYPE.black>
                <TYPE.main>{`${rewardPerDay} ${stakingTokenName} / day`}</TYPE.main>
              </TextRow>
            </div>
          )}

          <TextRow>
            <TYPE.black> TVL </TYPE.black>
            {isVault ? (
              <TYPE.main color="primary1">
                {vaultTotalDeposited
                  ? `$${new BigNumber(vaultTotalDeposited).multipliedBy(moxiePrice).toFixed(3)} `
                  : '$0 '}
              </TYPE.main>
            ) : (
              <TYPE.main color="primary1">
                ${isTokenOnly ? `5672` : totalValue ? totalValue.toNumber().toFixed(2) : 0}
              </TYPE.main>
            )}
          </TextRow>
        </div>
      ) : null}
    </CardCompoent>
  )
}
