/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import vaults from 'constants/config/vaults'
import sousChefABI from 'constants/abis/sousChef.json'
import vaultDetailABI from 'constants/abis/vaultDetailABI.json'
import vaultABI from 'constants/abis/vault.json'
import erc20ABI from 'constants/abis/erc20.json'
import { QuoteToken } from 'constants/config/types'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { getWeb3NoAccount } from 'utils/web3'
import BigNumber from 'bignumber.js'

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
// const nonBnbPools = poolsConfig.filter(
//   (p) => p.stakingTokenName !== QuoteToken.BNB
// );
const poolsConfig = vaults
const bnbPools = poolsConfig.filter((p: { stakingTokenName: any }) => p.stakingTokenName === QuoteToken.BNB)
const noVaultPools = poolsConfig.filter(eachPool => eachPool.isVault === false)
const vaultPools = poolsConfig.filter(eachPool => eachPool.isVault === true)
const nonMasterPools = poolsConfig.filter((p: { sousId: number }) => p.sousId !== 0)
const web3 = getWeb3NoAccount()

export const fetchPoolsAllowance = async (account: string, pools: any) => {
  const calls = pools.map(p => ({
    address: p.stakingTokenAddress,
    name: 'allowance',
    params: [account, getAddress(p.contractAddress)]
  }))
  console.log({ calls })
  //@ts-ignore
  const allowances = await multicall(erc20ABI, calls)
  return pools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: new BigNumber(allowances[index]).toJSON()
    }),
    {}
  )
}

export const fetchUserBalances = async (account: string, pools: any) => {
  const bnbPools = pools.filter((p: { stakingTokenName: any }) => p.stakingTokenName === QuoteToken.BNB)
  // Non BNB pools
  const calls = pools.map(p => ({
    address: p.stakingTokenAddress,
    name: 'balanceOf',
    params: [account]
  }))
  //@ts-ignore
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  const tokenBalances = pools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON()
    }),
    {}
  )

  // BNB pools
  const bnbBalance = await web3.eth.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc: any, pool: { sousId: any }) => ({
      ...acc,
      [pool.sousId]: new BigNumber(bnbBalance).toJSON()
    }),
    {}
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account: string, pools: any) => {
  const calls = pools.map(p => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account]
  }))
  // console.log({ calls })
  const rawStakedBalances = await multicall(vaultDetailABI, calls)
  // console.log({ rawStakedBalances })
  const parsedStakedBalances = rawStakedBalances.map(stakedBalance => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  const unlockTimes = rawStakedBalances.map(stakedBalance => {
    return new BigNumber(stakedBalance[1]._hex)
  })
  return { parsedStakedBalances, unlockTimes }
}

export const fetchUserPendingRewards = async (account: string) => {
  const calls = nonMasterPools.map((p: { contractAddress: any }) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account, '0']
  }))

  const res = await multicall(sousChefABI, calls)
  const pendingRewards = nonMasterPools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON()
    }),
    {}
  )

  return {
    ...pendingRewards
  }
}

export const fetchPoolUserCanHarvestPendingReward = async (account: any) => {
  const calls = nonMasterPools.map((p: { contractAddress: any }) => ({
    address: getAddress(p.contractAddress),
    name: 'canHarvest',
    params: [account]
  }))

  const res = await multicall(sousChefABI, calls)
  const userCanHarvest = nonMasterPools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: res[index][0]
    }),
    {}
  )
  return { ...userCanHarvest }
}

export const fetchPoolUserHarvestInterval = async (account: any) => {
  const calls = nonMasterPools.map((p: { contractAddress: any }) => ({
    address: getAddress(p.contractAddress),
    name: 'getHarvestUntil',
    params: [account]
  }))

  const res = await multicall(sousChefABI, calls)
  const userHarvestInterval = nonMasterPools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON()
    }),
    {}
  )
  return { ...userHarvestInterval }
}

export const fetchUserVestingInfo = async (account: string) => {
  const calls = noVaultPools.map((p: { contractAddress: any }) => ({
    address: getAddress(p.contractAddress),
    name: 'getUserVestingInfo',
    params: [account]
  }))
  const userInfo = await multicall(sousChefABI, calls)
  const VestingInfo = noVaultPools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: userInfo[index][0].hasOptForVesting
    }),
    {}
  )
  return { ...VestingInfo }
}
export const fetchVaultUserDepositInfo = async (account: string) => {
  const calls = vaultPools.map((p: { contractAddress: any }) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account]
  }))
  const userDepositInfo = await multicall(vaultABI, calls)
  const depositInfo = vaultPools.reduce(
    (acc: any, pool: { sousId: any }, index: string | number) => ({
      ...acc,
      [pool.sousId]: userDepositInfo[index].amountLocked._hex
    }),
    {}
  )
  return { ...depositInfo }
}
export const fetchUserEarnedInfo = async (account: string) => {
  const calls = poolsConfig.map((p: { contractAddress: any }) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account]
  }))

  const rawEarnings = await multicall(vaultDetailABI, calls)
  // console.log({ rawEarnings })
  const parsedEarnings = rawEarnings.map(earnings => {
    const userAmountLocked = new BigNumber(parseFloat(earnings.amountLocked))
    const rewardEarned = (parseFloat(userAmountLocked) * parseFloat(rewardPercent)) / 100
    return new BigNumber(rewardEarned)
  })
  return parsedEarnings
}
