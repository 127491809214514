/* eslint-disable @typescript-eslint/ban-ts-ignore */
import addresses from 'constants/config/contracts'
import { Address } from 'constants/config/types'
import { ChainId } from '@uniswap/sdk'

export const parseAddress = (currAddress: Address, chainId: ChainId | undefined) => {
  // @ts-ignore
  return currAddress[chainId]
}

export const getAddress = (address: Address): string => {
  let chainId = process.env.REACT_APP_CHAIN_ID
  // @ts-ignore
  if (window && window.ethereum && window.ethereum.networkVersion) {
    // @ts-ignore
    chainId = window.ethereum.networkVersion
  }
  // @ts-ignore
  return address[chainId]
}
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}
export const getWbnbAddress = () => {
  return getAddress(addresses.wbnb)
}
export const getMoxieAddress = () => {
  return getAddress(addresses.moxie)
}
export const getFarmAddress = () => {
  return getAddress(addresses.farm)
}
export const getMoxieBurnAddress = () => {
  return getAddress(addresses.moxieburn)
}
