import { ChainId } from '@uniswap/sdk'

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'https://ipfs.io/ipfs/QmPbZNhtbKegAcE26B2niA4nRUQxCwn7iDMRTd9VaLL6he'
export const POLYGON_TESTNET_LIST = 'https://ipfs.io/ipfs/QmPbZNhtbKegAcE26B2niA4nRUQxCwn7iDMRTd9VaLL6he'
// 'https://raw.githubusercontent.com/Parv250/list/main/worldofdefiListtt.json'
// 'https://raw.githubusercontent.com/Parv250/list/main/list4.json'
export const OPTIMISM_LIST = 'https://ipfs.io/ipfs/QmW5gg4koUbGrM3CJLPKapDfZfGib6fYTMUQnKA4HR9EZd'
export const ARBITRUM_LIST = 'https://ipfs.io/ipfs/QmSGf2Gpev1aU5bayjbNGqcczF6FdDh7z8QntDrorGsGrW'
export const AURORA_LIST = 'https://ipfs.io/ipfs/QmS5nunmiFk7dNScGwfXoqETMFpHNdTNbLGvhT23tPfKpv'
export const POLYGON_LIST = 'https://ipfs.io/ipfs/QmVw3BUE2vBdq4f9qGA3AQnPJn63mjGjJE9XpgTLLKDW9J'
export const AVALANCHE_LIST = 'https://ipfs.io/ipfs/QmWBJ3sFX1LdwRboV6DFSFhTqM3QyRjHEQHBBURfgp2qHn'
export const ETHEREUM_LIST = 'https://ipfs.io/ipfs/QmTgxSxswn1vJQXo7hdDeBB7iUY4WxkUnj2siyqtZUBqPv'
// const CMC_ALL_LIST = 'https://api.coinmarketcap.com/data-api/v3/uniswap/all.json'
export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
  ARBITRUM_LIST,
  OPTIMISM_LIST,
  AURORA_LIST,
  POLYGON_LIST,
  POLYGON_TESTNET_LIST
  // ETHEREUM_LIST
  // CMC_ALL_LIST
  // 't2crtokens.eth', // kleros
  // 'tokens.1inch.eth', // 1inch
  // 'synths.snx.eth',
  // 'tokenlist.dharma.eth',
  // 'defi.cmc.eth',
  // 'erc20.cmc.eth',
  // 'stablecoin.cmc.eth',
  // 'tokenlist.zerion.eth',
  // 'tokenlist.aave.eth',
  // 'https://tokens.coingecko.com/uniswap/all.json',
  // 'https://app.tryroll.com/tokens.json',
  // 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
  // 'https://defiprime.com/defiprime.tokenlist.json',
  // 'https://umaproject.org/uma.tokenlist.json'
]
export const CHAINLINKS = {
  [ChainId.MAINNET]: ETHEREUM_LIST,
  [ChainId.MATIC_TESTNET]: POLYGON_TESTNET_LIST,
  [ChainId.MATIC]: POLYGON_LIST,
  [ChainId.AVALANCHE]: AVALANCHE_LIST,
  [ChainId.ARBITRUM]: ARBITRUM_LIST,
  [ChainId.OPTIMISM]: OPTIMISM_LIST,
  [ChainId.AURORA]: AURORA_LIST
}
