// @ts-nocheck

import { PoolConfig, QuoteToken } from './types'

const farms: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    tokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xf9c8B7d40c30521D212C67c8082d1cB962cC44B7',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    tokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    contractAddress: {
      97: '',
      56: '',
      80001: '0x36F16361D3574Ec011FBE8b23B80efeb1fe0E8EA',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    tokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC485BcBE0A1A3933be8a8c86A3D5C5E45beEcAA6',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    tokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    contractAddress: {
      97: '',
      56: '',
      80001: '0x3E18e2aFC1d1807F428A7f727732ED1920FFf35E',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const farmsEtherum: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    tokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x139cD41f11D2C2Ec3c34C6507AD9e7D8d4D7C1dF',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    tokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x15E32897deF0F87c8d9A0679832658d97E3246d2',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    tokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x6858CA0D3695260a5fb16991220300305D160222',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    tokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const farmsPolygon: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    tokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x7f54527550F141Ed8127b0a757499EddA62aD251',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    tokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x4bAA01eA8bB609621B57D02d35a6e56c24009453',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    tokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x563063A444De72B7d69a8c82AC7C76f0e0cD5Ab2',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    tokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const farmsArbitrum: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0x54C092AF247A869772A6b746b0B2A179323CcA1A',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const farmsOptimism: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485',
      10: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485',
      10: '0xF3c5367f6baaC4474b245b88edd9117127a5dF97'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485',
      10: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const farmsAvalanche: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    tokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x66833a6b19884E0bFA047b967f870799A4b30af3',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    tokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0xE7c8311fEEe6B3996c54A18DBA8763AfF9Dfc3d3',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    tokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x158a96f0b0243d67E3fa185EA9cAcaBA12947Fd5',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    tokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const farmsArora: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-3Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x66833a6b19884E0bFA047b967f870799A4b30af3',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a',
      1313161554: '0x2EbfE435592053B0458e0Bff721b05Db2695d266'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 90,
    isTokenOnly: true
  },
  {
    sousId: 1,
    tokenName: 'MOXIE-6Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x66833a6b19884E0bFA047b967f870799A4b30af3',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a',
      1313161554: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 180,
    isTokenOnly: true
  },
  {
    sousId: 2,
    tokenName: 'MOXIE-12Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x66833a6b19884E0bFA047b967f870799A4b30af3',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a',
      1313161554: '0xfe3088dB7301D4673Bed01a5C760DA55C72ed1b2'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 365,
    isTokenOnly: true
  },
  {
    sousId: 3,
    tokenName: 'MOXIE-24Months',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.MOXIE,
    stakingTokenAddress: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    contractAddress: {
      97: '',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0xeBe5D8cc8BB9d73ea0392610A9512c0d38b5AC86',
      137: '0x3a7f8325dd965bae85D61a54871830395a46e83a',
      42161: '0xfbE4BA1e1D575a76dD64053ac0aa744c5B8c06D6',
      43114: '0x66833a6b19884E0bFA047b967f870799A4b30af3',
      10: '0x0898829B66d0ed68338Acc8522d47AC97FE6e83a',
      1313161554: '0x89392c5bD378718F463e1F3A2CFE62c5136877fa'
    },
    quoteTokenCoinGeckoId: 'tether',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    isVault: true,
    lockupPeriod: 730,
    isTokenOnly: true
  }
]
const allFarms = {
  1: farmsEtherum,
  80001: farms,
  137: farmsPolygon,
  42161: farmsArbitrum,
  10: farmsOptimism,
  43114: farmsAvalanche,
  1313161554: farmsArora
}
export const getPools = (chainId: any) => {
  if (chainId) {
    return allFarms[chainId]
  } else {
    return allFarms[137]
  }
}
export default farms
