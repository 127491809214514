/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable consistent-return */
// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { AutoColumn } from '../../components/Column'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import sousChefABI from 'constants/abis/sousChef.json'
import erc20 from 'constants/abis/erc20.json'
import { usePriceBnbBusd } from 'state/hooks'
import Typography from '@material-ui/core/Typography'
// import { STAKING_REWARDS_INFO, VAULT_REWARDS_INFO } from '../../state/stake/hooks'
import { TYPE, ExternalLink } from '../../theme'
import FarmCard from '../../components/earn/FarmCard'
import { RowBetween } from '../../components/Row'
// import { useVaultInfo } from '../../state/stake/hooks'
import { usePools, useFarms } from '../../state/hooks'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
//@ts-ignore
import { Countdown } from './Countdown'
// import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { useLocation } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { margin } from 'polished'
import { getMoxieAddress, getFarmAddress, getMoxieBurnAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import glass from '../../assets/svg/magnifying-glass-solid.svg'
import icon1 from '../../assets/svg/icons/Vector.svg'
import icon2 from '../../assets/svg/icons/Union.svg'
import icon3 from '../../assets/svg/icons/Union-1.svg'
import icon4 from '../../assets/svg/icons/Layer 1.svg'
import icon5 from '../../assets/svg/icons/Group.svg'

// import VaultCard from 'components/earn/VaultCard'
import CountUp from 'react-countup'
const TopSection = styled(AutoColumn)`
  width: 100%;
`
const CardCompoent = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 20px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.bg1};
`
const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`
const AntSwitch = withStyles(theme => ({
  root: {
    width: 36,
    height: 28,
    padding: 0,
    marginTop: 6,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(17px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: `#fdcd06`,
        borderColor: '#fdcd06'
      }
    }
  },
  thumb: {
    width: 18,
    height: 18,
    marginRight: 2
  },
  track: {
    width: 42,
    height: 11,
    margin: '5px 0px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch)

export default function Earn() {
  const { account } = useActiveWeb3React()
  const [data, setData] = useState<Pool[] | []>([])
  const [tvl, setTotalTvl] = useState('0')
  const [MoxieStats, setMoxieStats] = useState({
    totalSupply: '0',
    totalBurned: '0',
    circulatingSupply: '0',
    marketCap: '0',
    MoxiePerBlock: '0'
  })
  const [stakedOnly, setStakedOnly] = useState(false)
  const [searchQuwery, setSearchQuery] = useState('')
  // const stakingInfos = useStakingInfo()
  // const vaultInfos = useVaultInfo()
  // @ts-ignore
  const pools = usePools()
  const moxiePrice = usePriceBnbBusd()
  const farms = useFarms()
  const location = useLocation()
  const [showMore, setShowMore] = useState(false)

  // const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)
  // const vaultRewardsExist = Boolean(typeof chainId === 'number' && (VAULT_REWARDS_INFO[chainId]?.length ?? 0) > 0)
  const handleStakedChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    if (event.target.checked) {
      // eslint-disable-next-line
      const stakedData = data.filter((eachPool: Pool) => {
        if (eachPool && eachPool.userData && new BigNumber(eachPool.userData.stakedBalance).isGreaterThan(0)) {
          return eachPool
        }
      })
      setData([...stakedData])
    } else {
      if (location && location.pathname === '/single-asset') {
        setData([...pools])
      } else if (location && location.pathname === '/moxie') {
        setData([...farms])
      }
    }
    setStakedOnly(event.target.checked)
  }
  const filterData = (event: any) => {
    const query = event.target.value
    if (query && query.length > 0) {
      // eslint-disable-next-line
      const filteredData = data.filter((eachPool: Pool) => {
        if (eachPool.tokenName.toLowerCase().includes(query.toLowerCase())) {
          return eachPool
        }
      })
      setData([...filteredData])
    } else {
      if (location && location.pathname === '/single-asset') {
        setData([...pools])
      } else if (location && location.pathname === '/moxie') {
        setData([...farms])
      }
    }
    setSearchQuery(query)
  }
  useEffect(() => {
    if (stakedOnly) {
      if (location && location.pathname === '/single-asset') {
        // eslint-disable-next-line
        const stakedData = data.filter(eachPool => {
          if (eachPool && eachPool.userData && new BigNumber(eachPool.userData.stakedBalance).isGreaterThan(0)) {
            return eachPool
          }
        })
        setData(stakedData)
      } else if (location && location.pathname === '/moxie') {
        const stakedData = data.filter(eachPool => {
          if (eachPool && eachPool.userData && new BigNumber(eachPool.userData.stakedBalance).isGreaterThan(0)) {
            return eachPool
          }
        })
        setData(stakedData)
      }
    } else {
      if (location && location.pathname === '/single-asset') {
        setData(pools)
      } else if (location && location.pathname === '/moxie') {
        setData(farms)
      }
    }
    const getTvl = async () => {
      //console.log({ farms, pools })
      let farmTvl = new BigNumber(0)
      let poolTvl = new BigNumber(0)
      farms.forEach(farm => {
        farmTvl = farmTvl.plus(farm.lpTotalInQuoteToken ? farm.lpTotalInQuoteToken : 0)
      })
      pools.forEach(pool => {
        poolTvl = poolTvl.plus(pool.vaultTotalDeposited ? pool.vaultTotalDeposited : 0)
      })
      const finalTvl = farmTvl.multipliedBy(moxiePrice).plus(poolTvl.multipliedBy(moxiePrice))
      setTotalTvl(finalTvl.toFixed(2))
    }
    getTvl()
  }, [farms, location, pools])
  useEffect(() => {
    const getStats = async () => {
      const MoxieAddress = getMoxieAddress()
      const farmAddress = getFarmAddress()
      const moxieBurnAddress = getMoxieBurnAddress()
      const erc20Calls = [
        {
          address: MoxieAddress,
          name: 'totalSupply'
        },
        {
          address: MoxieAddress,
          name: 'balanceOf',
          params: [moxieBurnAddress]
        },
        {
          address: MoxieAddress,
          name: 'decimals'
        }
      ]
      const callsRewards = [
        {
          address: farmAddress,
          name: 'MoxiePerBlock'
        }
      ]
      const [totalSupply, totalBurned, decimals] = await multicall(erc20, erc20Calls)
      const [MoxiePerBlock] = await multicall(sousChefABI, callsRewards)
      const totalSupplyInEth = new BigNumber(totalSupply[0].toString()).dividedBy(10 ** decimals[0])
      const totalBurnedInEth = new BigNumber(totalBurned[0].toString()).dividedBy(10 ** decimals[0])
      const circulatingSupply = totalSupplyInEth.minus(totalBurnedInEth)
      const marketCap = circulatingSupply.multipliedBy(moxiePrice.toString())
      setMoxieStats({
        totalSupply: totalSupplyInEth.toString(),
        totalBurned: totalBurnedInEth.toString(),
        circulatingSupply: circulatingSupply.toString(),
        marketCap: marketCap.toFixed(3),
        MoxiePerBlock: new BigNumber(MoxiePerBlock[0].toString()).dividedBy(10 ** decimals[0]).toString()
      })
    }
    getStats()
  }, [])

  return (
    <Container maxWidth="lg">
      <CardCompoent>
        <div>
          <TYPE.black fontSize={32} mr="10px">
            Total Value Locked
          </TYPE.black>
          <RowBetween paddingTop="10px">
            {/* <TYPE.main fontSize={16} mr="10px">
                TVL
              </TYPE.main> */}
            <TYPE.mediumHeader fontSize={32} fontWeight={700}>
              $<CountUp separator="," end={tvl} decimals={2} />
            </TYPE.mediumHeader>
          </RowBetween>
          {/* <TYPE.main
            color="primary1"
            style={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Hide' : 'Details'} &nbsp;
            {showMore ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}
          </TYPE.main> */}
        </div>
      </CardCompoent>
      <div className="grid-container">
        {/* <div className="  grid-item">MOXIE Stats</div> */}

        <div className="glass-effect grid-item">
          <div className="icon-stats">
            <img src={icon2} style={{ height: '48px', width: '48px', marginTop: '8px' }} />
          </div>
          <div style={{ padding: '14px', display: 'flex-col' }}>
            <TYPE.black fontSize={16} mr="10px">
              Total Minted
            </TYPE.black>
            <TYPE.blue fontSize={20} fontWeight={700}>
              <CountUp end={MoxieStats.totalSupply} decimals={2} separator="," />
            </TYPE.blue>
          </div>
        </div>
        <div className="glass-effect grid-item">
          <div className="icon-stats">
            <img src={icon1} style={{ height: '48px', width: '48px', marginTop: '8px' }} />
          </div>
          <div style={{ padding: '14px', display: 'flex-col' }}>
            <TYPE.black fontSize={16} mr="10px">
              Total Burned
            </TYPE.black>
            <TYPE.blue fontSize={20} fontWeight={700}>
              {/* <CountUp end={MoxieStats.totalBurned} decimals={2} /> */}
              <CountUp end={MoxieStats.totalBurned} decimals={2} separator="," />
            </TYPE.blue>
          </div>
        </div>
        <div className="glass-effect grid-item">
          <div className="icon-stats">
            <img src={icon5} style={{ height: '48px', width: '48px', marginTop: '8px' }} />
          </div>
          <div style={{ padding: '14px', display: 'flex-col' }}>
            <TYPE.black fontSize={16} mr="10px">
              Circulating Supply
            </TYPE.black>
            <TYPE.blue fontSize={20} fontWeight={700}>
              {/* <CountUp end={MoxieStats.circulatingSupply} decimals={2} /> */}
              <CountUp end={MoxieStats.circulatingSupply} decimals={2} separator="," />
            </TYPE.blue>
          </div>
        </div>
        <div className="glass-effect grid-item">
          <div className="icon-stats">
            <img src={icon3} style={{ height: '48px', width: '48px' }} />
          </div>
          <div style={{ padding: '14px', display: 'flex-col' }}>
            <TYPE.black fontSize={16} mr="10px">
              Market Cap
            </TYPE.black>
            <TYPE.blue fontSize={20} fontWeight={700}>
              ${' '}
              <CountUp
                end={new BigNumber(MoxieStats.circulatingSupply).multipliedBy(moxiePrice.toString())}
                separator=","
                duration={0.1}
              />
            </TYPE.blue>
          </div>
        </div>
        <div className="glass-effect grid-item">
          {/* <div className="icon-stats">
            <img src={icon4} style={{ height: '48px', width: '48px' }} />
          </div> */}
          <div style={{ padding: '14px', display: 'flex-col' }}>
            <TYPE.black fontSize={16} mr="10px">
              New MOXIE/block
            </TYPE.black>
            <TYPE.blue fontSize={20} fontWeight={700}>
              {MoxieStats.MoxiePerBlock} {/* <CountUp end={MoxieStats.MoxiePerBlock} decimals={2} /> */}
            </TYPE.blue>
          </div>
        </div>
      </div>
      {location.pathname === '/moxie' ? (
        <div style={{ width: '100%' }}>
          <TopSection gap="md">
            <DataCard>
              <CardBGImage />
              <CardNoise />
              <CardSection>
                <AutoColumn gap="md">
                  <RowBetween>
                    <TYPE.black fontWeight={600}>Farming Pools</TYPE.black>
                  </RowBetween>
                  <RowBetween>
                    <TYPE.mediumHeader fontSize={14}>
                      Deposit your Liquidity Provider tokens to receive REWARDS, the Moxie protocol governance token.
                    </TYPE.mediumHeader>
                  </RowBetween>
                  <ExternalLink style={{ color: 'white', textDecoration: 'underline' }} href="#" target="_blank">
                    {/* <TYPE.white fontSize={14}>Read more about CHILLI</TYPE.white> */}
                  </ExternalLink>
                </AutoColumn>
              </CardSection>
              <CardBGImage />
              <CardNoise />
            </DataCard>
          </TopSection>
          {/* <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px', marginTop: '15px' }}>
            <DataRow style={{ alignItems: 'baseline' }}>
              <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating pools</TYPE.mediumHeader>
            </DataRow>
          </AutoColumn> */}
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <TopSection gap="md">
            <DataCard>
              <CardBGImage />
              <CardNoise />
              <CardSection>
                <AutoColumn gap="md">
                  <RowBetween>
                    <TYPE.black fontWeight={600}>Single Asset Vaults</TYPE.black>
                  </RowBetween>
                  <RowBetween>
                    <TYPE.mediumHeader fontSize={14}>
                      Lock MOXIE token for a period of time and earn a fixed number of MOXIE tokens.
                    </TYPE.mediumHeader>
                  </RowBetween>{' '}
                  <ExternalLink style={{ color: 'white', textDecoration: 'underline' }} href="#" target="_blank">
                    {/* <TYPE.white fontSize={14}>Read more about CHILLI</TYPE.white> */}
                  </ExternalLink>
                </AutoColumn>
              </CardSection>
              <CardBGImage />
              <CardNoise />
            </DataCard>
          </TopSection>
          {/* <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
            <DataRow style={{ alignItems: 'baseline' }}>
              <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating Vaults</TYPE.mediumHeader>
            </DataRow>
          </AutoColumn> */}
        </div>
      )}
      <Grid container alignItems="center" spacing={3}>
        <Grid style={{ margin: '10px 0px 0px 0px', padding: '20px 0px 20px 15px' }}>
          <div
            style={{
              borderRadius: '10px',
              border: '1px solid #fdcd06',
              width: '500px',
              display: 'flex',
              background: '#fff'
            }}
            className="search-input"
          >
            <img src={glass} style={{ height: '16px', width: '16px', alignItems: 'center', margin: '16px 12px' }} />
            <input
              type="text"
              placeholder="Search by name, symbol"
              style={{
                fontSize: '18px',
                borderRadius: '10px',
                padding: '10px 5px',
                border: '0px solid #fdcd06',
                width: '500px'
              }}
              className="search-input"
              value={searchQuwery}
              onChange={filterData}
            />
          </div>
          {/* <TextField
            className="textField"
            id="outlined-basic"
            label=""
            placeholder="Search Pools"
            fullWidth={true}
            style={{ borderRadius: '12px', padding: '10px 15px', border: '' }}
            variant="standard"
            value={searchQuwery}
            onChange={filterData}
          /> */}
        </Grid>
        <Grid item sx={12} md={6} lg={4} key="infocontainerright">
          <Typography component="div">
            <Grid
              component="label"
              style={{ marginTop: '10px' }}
              container
              alignItems="center"
              key="infoinnercontainer"
            >
              <Grid item key="infoinnercontainer1">
                <TYPE.main color="primary1" fontSize={18} fontWeight={600} marginRight={10}>
                  Staked Only
                </TYPE.main>
              </Grid>
              <Grid item key="infoinnercontainer2">
                <AntSwitch checked={stakedOnly} onChange={handleStakedChange} name="checkedC" />
              </Grid>
              {/* <Grid item key="infoinnercontainer3">
                <div
                  style={{
                    marginLeft: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <TYPE.main fontSize={16} mr="10px">
                    TVL
                  </TYPE.main>
                  <TYPE.blue fontSize={20} fontWeight={700}>
                    ${tvl}
                  </TYPE.blue>
                </div>{' '}
              </Grid> */}
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} className="custom-grid">
        {data?.length > 0 &&
          data.map(eachPool => {
            return (
              <Grid alignItems="center" item key={eachPool.stakingRewardAddress}>
                <FarmCard pool={eachPool} key={eachPool.id} />
              </Grid>
            )
          })}
        {/* {location.pathname === '/uni' && stakingRewardsExist && stakingInfos?.length === 0 ? (
          <Loader style={{ margin: 'auto' }} />
        ) : !stakingRewardsExist ? (
          'No active rewards'
        ) : (
          stakingInfos?.map(stakingInfo => {
            // need to sort by added liquidity here
            return (
              <>
                {location.pathname === '/uni' && (
                  <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
                )}
              </>
            )
          })
        )} */}

        {/* {location.pathname === '/single-asset' && vaultRewardsExist && vaultInfos?.length === 0 ? (
          <Loader style={{ margin: 'auto' }} />
        ) : !vaultRewardsExist ? (
          'No active rewards'
        ) : (
          vaultInfos?.map(vaultInfo => {
            // need to sort by added liquidity here
            return (
              <>
                {location.pathname === '/single-asset' && (
                  <VaultCard key={vaultInfo.vaultRewardAddress} vaultInfo={vaultInfo} />
                )}
              </>
            )
          })
        )} */}
      </Grid>
    </Container>
  )
}
