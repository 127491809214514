/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import { PoolConfig, QuoteToken } from './types'

const farms: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    tokenAddress: '0x0aCBd07e458F228d4869066b998a0F55F36537B2',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0x179e0e6bEE04A5Ca3f15392c7f94f26E7f4A5aB8',
    contractAddress: {
      97: '',
      10: '',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      137: '0x4331B616e75006C6777d160099AD15ee2437612d' // wrong
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const farmsEtherum: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0x32314BA4D5A492A98e2f030541575e2744f32E65',
    contractAddress: {
      97: '',
      10: '',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54' // wrong
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const farmsPolygon: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    tokenAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0xf53FCE98ba04689a6a3206B92f55A1bCB72E9307',
    contractAddress: {
      97: '',
      10: '',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54' // wrong
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const farmsArbitrum: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0xaEf13d8c357eE53d3572a457f95B9070eB7C6F0d',
    contractAddress: {
      97: '',
      10: '',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C'
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const farmsOptimism: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0xF6B726e715BD58b94bfE02e974c599e8deF93225',
    contractAddress: {
      97: '',
      10: '0x4bAA01eA8bB609621B57D02d35a6e56c24009453',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const farmsAvalanche: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0xcfac56ee64a22b03689a613b994efa0771f36832',
    tokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0xb797827e1048650b1b73adde8dEfF6987Be9ebbe',
    contractAddress: {
      97: '',
      10: '',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const farmsArora: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOXIE-USDT',
    tokenAdressInLp: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    tokenAddress: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
    stakingTokenName: QuoteToken.MOXIE,
    quoteTokenSymbol: QuoteToken.USDT,
    stakingTokenAddress: '0x5b9ecf40677983ad209Ef1f6A86917AA2DeC62e9',
    contractAddress: {
      97: '',
      10: '',
      1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      56: '',
      80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
      5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
      1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
      137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54',
      42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
      43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485'
    },
    quoteTokenCoinGeckoId: 'matic-network',
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 0,
    isFinished: false,
    tokenDecimals: 18,
    isVault: false,
    isTokenOnly: false
  }
]
const allFarms = {
  1: farmsEtherum,
  80001: farms,
  137: farmsPolygon,
  42161: farmsArbitrum,
  10: farmsOptimism,
  43114: farmsAvalanche,
  1313161554: farmsArora
}
export const getFarms = (chainId: any) => {
  if (chainId) {
    return allFarms[chainId]
  } else {
    return allFarms[137]
  }
}
export default farms
