// @ts-nocheck
import React, { useState, useCallback } from 'react'
// import Web3 from 'web3'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import { getAddress } from 'utils/addressHelpers'
import styled from 'styled-components'
import { useVaultApprove } from 'hooks/useApprove'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
// import { useCurrency } from '../../hooks/Tokens'
import useWeb3 from 'hooks/useWeb3'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { Pool } from '../../state/types'
import { TokenAmount, Token } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { useVaultContract, useTokenContract } from '../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'

// const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
//   display: flex;
//   justify-content: space-between;
//   padding-right: 20px;
//   padding-left: 20px;

//   opacity: ${({ dim }) => (dim ? 0.5 : 1)};
// `

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalVaultProps {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: Pool
  userLiquidityUnstaked: TokenAmount | undefined
}

export default function StakingModalVault({
  isOpen,
  onDismiss,
  poolInfo,
  userLiquidityUnstaked
}: StakingModalVaultProps) {
  // console.log(poolInfo)
  const { account, chainId } = useActiveWeb3React()
  const web3 = useWeb3()
  // track and parse user input
  const currency = new Token(
    chainId,
    poolInfo.stakingTokenAddress,
    poolInfo.tokenDecimals,
    poolInfo.stakingTokenName,
    poolInfo.stakingTokenName
  )
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const [attemptngApprove, setAttemptingApprove] = useState<boolean>(false)
  const [typedValue, setTypedValue] = useState('')
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])
  const stakingContract = useVaultContract(getAddress(poolInfo.contractAddress))
  async function onStake() {
    // console.log(typedValue)
    const valInWei = web3.utils.toWei(typedValue.toString())
    setAttempting(true)
    if (stakingContract) {
      stakingContract
        .lockStakingTokenToParticipate(valInWei, { gasLimit: 350000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Deposited Token`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          //console.log(error)
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    // setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  const atMaxAmount = Boolean(userLiquidityUnstaked === typedValue)
  const handleMax = useCallback(() => {
    selectedCurrencyBalance &&
    parseFloat(selectedCurrencyBalance?.toSignificant(6)) <=
      parseFloat(poolInfo.vaultPoolSize) - parseFloat(poolInfo.vaultTotalDeposited)
      ? onUserInput(selectedCurrencyBalance?.toSignificant(6))
      : onUserInput(parseFloat(poolInfo.vaultPoolSize) - parseFloat(poolInfo.vaultTotalDeposited))
  }, [onUserInput, selectedCurrencyBalance])
  const tokencontract = useTokenContract(poolInfo.stakingTokenAddress)
  const { onApprove } = useVaultApprove(tokencontract, poolInfo.sousId)
  async function onAttemptToApprove() {
    setAttemptingApprove(false)
    await onApprove()
    setAttemptingApprove(true)
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {/* // sanchit */}
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={currency}
            // pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id="stake-liquidity-token"
          />
          {/* // sanchit */}
          {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.black fontWeight={600}>Weekly Rewards</TYPE.black>
            </div>

            <TYPE.black>
              // sanchit 
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              UNI / week
            </TYPE.black>
          </HypotheticalRewardRate> */}
          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={parseFloat(poolInfo.userData?.allowance.toString()) > 0}
              disabled={parseFloat(poolInfo.userData?.allowance.toString()) > 0}
            >
              {attemptngApprove ? 'Approving...' : 'Approve'}
            </ButtonConfirmed>
            <ButtonError
              disabled={parseFloat(poolInfo.userData?.allowance.toString()) === 0}
              // disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              // error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {attempting ? 'Staking...' : 'Deposit'}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[parseFloat(poolInfo.userData?.allowance.toString()) > 0]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Depositing {poolInfo.stakingTokenName} Token</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {typedValue} {poolInfo.stakingTokenName}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Deposited {typedValue} {poolInfo.stakingTokenName}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
