import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}
// samarth
export const network = new NetworkConnector({
  urls: {
    [NETWORK_CHAIN_ID]: NETWORK_URL,
    1: 'https://eth-mainnet.g.alchemy.com/v2/bLOUeund0-lmpVJBse9X8IBrZCLHibiK',
    137: 'https://polygon-rpc.com/',
    43114: 'https://api.avax.network/ext/bc/C/rpc',
    42161: 'https://arb1.arbitrum.io/rpc',
    10: 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
    1313161554: 'https://mainnet.aurora.dev',
    80001: 'https://rpc-mumbai.maticvigil.com/'
  },
  defaultChainId: 1
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}
// sam changes
export const injected = new InjectedConnector({
  supportedChainIds: [43114, 137, 1, 42161, 10, 1313161554, 80001]
  // supportedChainIds: [137]
})
// sam changes
// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})
