/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { ChainId, Currency, TokenAmount, CurrencyAmount, JSBI } from '@uniswap/sdk'
import React, { useState, useRef, useEffect } from 'react'
import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
import { Text } from 'rebass'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { darken, lighten } from 'polished'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MuiMenu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'
import Logo from '../../assets/logo-dark.png'
import LogoDark from '../../assets/logo.png'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useActiveWeb3React } from '../../hooks'
import { replaceURLParam } from '../../utils/routes'
import { AppDispatch } from '../../state'
import { useDarkModeManager } from '../../state/user/hooks'
import { selectList } from '../../state/lists/actions'
import { ExternalLink } from '../../theme'

import { YellowCard } from '../Card'
import Settings from '../Settings'
import Menu from '../Menu'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup, useSetChainId } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import Modal from '../Modal'
import { networks } from '../../constants'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import { CHAINLINKS } from '../../constants/lists'
import { getWeb3NoAccount } from 'utils/web3'
import rpcUrl from 'utils/getRpcUrl'
import { usePriceBnbBusd } from 'state/hooks'
import tokenLogo from '../../assets/logo.png'
import { registerToken } from 'utils/wallet'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: space-between;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  white-space: pre;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const HideNone = styled.span`
  white-space: pre;
`

const HideExtraSmall = styled.span`
  white-space: pre;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const HideBig = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`
const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 4px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 2px;
  font-weight: 500;
  padding: 10px 10px;
  transition: font-weight 0.5s ease;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  :hover {
    background-color: rgba(156, 118, 221, 0.1);
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
      font-size: 0.95rem;
`}
`
const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`
const StyledExternalLinkMobile = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledNav = styled.div`
  position: relative;
  cursor: pointer;
  margin: 0 12px;
`
const DropDown = styled.div`
  position: absolute;
  top: 105%;
  left: -0.25rem;
  min-width: 250px;
  padding: 0.75rem 0.5rem;
  background-color: ${({ theme }) => lighten(0.1, theme.bg1)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
const ActiveRowLinkList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  & > a {
    align-items: center;
    color: ${({ theme }) => theme.text2};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;
    padding: 8px 0 4px;
    text-decoration: none;
  }
  & > a:first-child {
    margin: 0;
    margin-top: 0px;
    padding-top: 10px;
  }
`
const ActiveRowWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  width: 100%;
`
const FlyoutHeader = styled.div`
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
`
const FlyoutMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.bg0};
  top: 54px;
  width: 272px;
  z-index: 99;
  padding-top: 10px;
`
const FlyoutMenuContents = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.bg0};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 16px;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`
const FlyoutRow = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active, theme }) => (active ? theme.bg1 : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
  text-align: left;
  width: 100%;
`
const FlyoutRowActiveIndicator = styled.div`
  background-color: ${({ theme }) => theme.green1};
  border-radius: 50%;
  height: 9px;
  width: 9px;
`
// const LinkOutCircle = styled(ArrowDownCircle)`
//   transform: rotate(230deg);
//   width: 16px;
//   height: 16px;
// `
const LogoImg = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`
const NetworkLabel = styled.div`
  flex: 1 1 auto;
`
const SelectorLabel = styled(NetworkLabel)``
const SelectorControls = styled.div<{ interactive: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.bg0};
  border: 2px solid ${({ theme }) => theme.bg0};
  border-radius: 16px;
  color: ${({ theme }) => theme.text1};
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
`
const SelectorLogo = styled(LogoImg)<{ interactive?: boolean }>`
  margin-right: ${({ interactive }) => (interactive ? 8 : 0)}px;
`
export interface StyleProps {
  isDark: boolean
}
const useStyles = makeStyles<StyleProps>(theme => ({
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: ({ isDark }) => (isDark ? '#2C2F36' : '#F7F8FA'),
      minWidth: '200px'
    }
  }
}))
const CustomMenuItem = styled(MenuItem)`
  background: transparent;
  border-radius: 12px !important;
  margin: 10px !important;
  :hover {
    background: ${({ theme }) => theme.bg5};
  }
`
const SelectorWrapper = styled.div``
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: 'Mainnet',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.MATIC_TESTNET]: 'Matic Testnet',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.FUJI]: 'AVAX Testnet',
  [ChainId.AVALANCHE]: 'AVAX Mainnet',
  [ChainId.ARBITRUM]: 'Arbitrum',
  [ChainId.OPTIMISM]: 'Optimism',
  [ChainId.AURORA]: 'Near Aurora Mainnet'
}
enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,
  AVALANCH = 43114,
  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  AURORA_MAINNET = 1313161554,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001
}
const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.OPTIMISTIC_KOVAN]: 'optimistic_kovan',
  [SupportedChainId.AURORA_MAINNET]: 'aurora',
  [SupportedChainId.AVALANCH]: 'avalanch'
}
const getChainNameFromId = (id: string | number) => {
  // casting here may not be right but fine to return undefined if it's not a supported chain ID
  return CHAIN_IDS_TO_NAMES[id as SupportedChainId] || ''
}
const getChainIdFromName = (name: string) => {
  const entry = Object.entries(CHAIN_IDS_TO_NAMES).find(([_, n]) => n === name)
  const chainId = entry?.[0]
  return chainId ? parseInt(chainId) : undefined
}
const getParsedChainId = (parsedQs?: ParsedQs) => {
  const chain = parsedQs?.chain
  if (!chain || typeof chain !== 'string') return { urlChain: undefined, urlChainId: undefined }

  return { urlChain: chain.toLowerCase(), urlChainId: getChainIdFromName(chain) }
}

export default function Header() {
  const dispatch = useDispatch<AppDispatch>()
  const setChainId = useSetChainId()
  const [isDark] = useDarkModeManager()
  const classes = useStyles({ isDark })
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const prevChainId = usePrevious(chainId)
  const history = useHistory()
  const parsedQs = useParsedQueryString()
  const { urlChain, urlChainId } = getParsedChainId(parsedQs)
  const node = useRef<HTMLDivElement>()
  const [active, setActive] = useState(false)
  const [buyCryptoActive, setBuyCryptoActive] = useState(false)
  const [showSwitch, setSwitchModal] = useState(false)
  const [userEthBalance, setUserEthBalance] = useState(CurrencyAmount.ether(JSBI.BigInt('0')))
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorForMenu, setAnchorForMenu] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const openMenu = Boolean(anchorForMenu)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorForMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorForMenu(null)
  }

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const moxiePrice = usePriceBnbBusd()

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  // const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  // const showClaimPopup = useShowClaimPopup()

  // const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  // const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  const switchNetwrok = async newtwork => {
    if (newtwork?.chainId && !account) {
      localStorage.setItem('networkdefault', 137)
    }

    // if (!account) {
    //   let localvar = localStorage.getItem('networkdefault')
    //   let previousNetworkVar = localStorage.getItem('networkprevious')
    //   if (localvar === previousNetworkVar) {
    //     return
    //   }

    //   if (!previousNetworkVar) {
    //     localStorage.setItem('networkprevious', localvar)
    //     localvar = localStorage.getItem('networkdefault')
    //     previousNetworkVar = localStorage.getItem('networkprevious')
    //     history.replace({
    //       search: replaceURLParam(history.location.search, 'chain', getChainNameFromId(newtwork.chainId))
    //     })
    //     // window.location.reload()
    //   } else if (localvar !== previousNetworkVar) {
    //     localStorage.setItem('networkprevious', localvar)
    //     localvar = localStorage.getItem('networkdefault')
    //     previousNetworkVar = localStorage.getItem('networkprevious')
    //     history.replace({
    //       search: replaceURLParam(history.location.search, 'chain', getChainNameFromId(newtwork.chainId))
    //     })
    //     // window.location.reload()
    //   }
    // }
    try {
      setSwitchModal(false)
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        // params: [{ chainId: '0x1' }],
        params: [{ chainId: `0x${parseFloat(newtwork.chainId).toString(16)}` }]
      })

      // history.push({
      //   search: replaceURLParam(history.location.search, 'chain', getChainNameFromId(newtwork.chainId))
      // })
      // const current_link = window.location.href;
      const nameofchain = getChainNameFromId(newtwork.chainId)
      // https://app.moxieswap.com

      // window.location.href = `http://localhost:3000/#/swap?chain=${nameofchain}&outputCurrency=ETH`
      if (CHAINLINKS[newtwork.chainId]) {
        console.log('dispatch')
        dispatch(selectList(CHAINLINKS[newtwork.chainId]))
      }
      // window.location.reload(false);
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.

      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${parseFloat(newtwork.chainId).toString(16)}`,
                rpcUrls: [newtwork.rpcUrl],
                chainName: newtwork.title,
                nativeCurrency: newtwork?.nativeCurrency,
                blockExplorerUrls: newtwork?.blockExplorerUrls
              }
            ]
          })
          const nameofchain = getChainNameFromId(newtwork.chainId)
          // window.location.href = `https://app.moxieswap.com/#/swap?chain=${nameofchain}&outputCurrency=ETH`
          // window.location.href = `http://localhost:3000/#/swap?chain=${nameofchain}&outputCurrency=ETH`
        } catch (addError) {
          // handle "add" error
          // history.replace({
          //   search: replaceURLParam(history.location.search, 'chain', getChainNameFromId(chainId))
          // })
        }
      }
    }
    // handle other "switch" errors
  }
  let currentNetwork = networks[0]
  if (chainId) {
    currentNetwork = networks.find(
      eachNetwork => eachNetwork.chainId.toLowerCase() === chainId.toString().toLowerCase()
    )
    if (!currentNetwork) {
      currentNetwork = networks[0]
    }
  }

  useEffect(() => {
    if (!chainId || !prevChainId) return

    // when network change originates from wallet or dropdown selector, just update URL
    if (chainId !== prevChainId) {
      history.replace({ search: replaceURLParam(history.location.search, 'chain', getChainNameFromId(chainId)) })
      // otherwise assume network change originates from URL
    } else if (urlChainId && urlChainId !== chainId) {
      const newtork = networks.find(eachNetwrok => eachNetwrok.chainId === urlChainId.toString())
      if (newtork) {
        switchNetwrok(newtork)
      }
    }
  }, [chainId, urlChainId, prevChainId, history, switchNetwrok])
  useEffect(() => {
    if (chainId && !urlChainId) {
      const localvar = localStorage.getItem('networkdefault')
      if (!localvar) {
        localStorage.setItem('networkdefault', 1)
      }

      history.replace({ search: replaceURLParam(history.location.search, 'chain', getChainNameFromId(chainId)) })
    }
  }, [chainId, history, urlChainId, urlChain])
  useEffect(() => {
    const rpc = rpcUrl()
    // console.log({ rpc })
    const httpProvider = new Web3.providers.HttpProvider(rpc, {
      timeout: 10000
    } as HttpProviderOptions)

    const web3 = new Web3(httpProvider)
    const getAccountBalance = async () => {
      const userbalance = await web3.eth.getBalance(account)
      setUserEthBalance(CurrencyAmount.ether(JSBI.BigInt(userbalance.toString())))
    }
    if (account) {
      getAccountBalance()
    }
  }, [account, chainId])
  return (
    <HeaderFrame className="glass-effect-gradient-moxie sticky">
      {/* <ClaimModal /> */}
      {/* <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}
      <HeaderRow>
        <Title href=".">
          <UniIcon>
            <img width={'140px'} src={isDark ? LogoDark : Logo} alt="logo" />
            {/* <UniTokenAnimated width="30px" src={Logo} /> */}
          </UniIcon>
        </Title>
        <HideBig>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
            startIcon={<SelectorLogo interactive src={currentNetwork.imgSrc} />}
            endIcon={<KeyboardArrowDownIcon />}
            style={{
              background: isDark ? '#40444F' : '#EDEEF2',
              boxShadow: 'none',
              color: isDark ? '#ffff' : '#000000',
              borderRadius: '12px',
              border: isDark ? '1px solid #40444F' : '1px solid #EDEEF2'
            }}
          >
            <HideSmall>{currentNetwork.title}</HideSmall>
          </Button>
          <IconButton onClick={handleClickMenu}>
            <MenuIcon />
          </IconButton>
        </HideBig>
        <MuiMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          id="customized-menu"
          anchorEl={anchorForMenu}
          keepMounted
          className={classes.menu}
          open={Boolean(anchorForMenu)}
          onClose={handleCloseMenu}
        >
          <CustomMenuItem>
            <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
              {t('swap')}
            </StyledNavLink>
          </CustomMenuItem>
          <CustomMenuItem>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/create') ||
                pathname.startsWith('/find')
              }
            >
              {t('pool')}
            </StyledNavLink>
          </CustomMenuItem>
          <CustomMenuItem>
            <StyledNavLink onClick={() => setActive(!active)} to={'/moxie'}>
              <span role="img" aria-labelledby="love">
                ⚡
              </span>
              FARMS
            </StyledNavLink>
          </CustomMenuItem>
          <CustomMenuItem>
            <StyledNavLink to={'/single-asset'}>
              <span role="img" aria-labelledby="love">
                ⚡
              </span>
              Staking
            </StyledNavLink>
          </CustomMenuItem>
          <CustomMenuItem>
            <StyledExternalLinkMobile id={`stake-nav-link`} href={'https://cbridge.celer.network/'}>
              Bridge <span style={{ fontSize: '11px' }}>↗</span>
            </StyledExternalLinkMobile>
          </CustomMenuItem>
          <CustomMenuItem>
            <StyledExternalLinkMobile id={`stake-nav-link`} href={'https://nftmarketplace.moxieswap.com/'}>
              Moxie NFT Marketplace <span style={{ fontSize: '11px' }}>↗</span>
            </StyledExternalLinkMobile>
          </CustomMenuItem>
        </MuiMenu>
        <HideSmall>
          <HeaderLinks>
            <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
              {t('swap')}
            </StyledNavLink>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/create') ||
                pathname.startsWith('/find')
              }
            >
              {t('pool')}
            </StyledNavLink>

            <StyledNavLink onClick={() => setActive(!active)} to={'/moxie'}>
              <span role="img" aria-labelledby="love">
                ⚡
              </span>
              FARMS
            </StyledNavLink>

            <StyledNavLink to={'/single-asset'}>
              <span role="img" aria-labelledby="love">
                ⚡
              </span>
              Staking
            </StyledNavLink>

            <StyledExternalLink id={`stake-nav-link`} href={'https://cbridge.celer.network/'}>
              Bridge <span style={{ fontSize: '11px' }}>↗</span>
            </StyledExternalLink>

            <StyledExternalLink id={`stake-nav-link`} href={'https://nftmarketplace.moxieswap.com/'}>
              Moxie NFT Marketplace <span style={{ fontSize: '11px' }}>↗</span>
            </StyledExternalLink>
          </HeaderLinks>
        </HideSmall>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          <div
            className="parent"
            style={{ justifyContent: 'space-between', display: 'flex', gap: '8px', alignItems: 'center' }}
          >
            {/* <div style={{ position: 'relative' }}>
              <span
                onClick={() => setBuyCryptoActive(!buyCryptoActive)}
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
              >
                Buy Crypto
              </span>
              {buyCryptoActive && (
                <DropDown>
                  <StyledExternalLink id={`stake-nav-link`} href="https://account.moonpay.com/buy">
                    Buy using MoonPay
                  </StyledExternalLink>
                  <StyledExternalLink id={`stake-nav-link`} href="https://ramp.network/buy/">
                    Buy using Ramp
                  </StyledExternalLink>
                </DropDown>
              )}
            </div> */}

            <HideNone className="left">
              <HideSmall>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  startIcon={<SelectorLogo interactive src={currentNetwork.imgSrc} />}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{
                    background: isDark ? '#40444F' : '#EDEEF2',
                    boxShadow: 'none',
                    color: isDark ? '#ffff' : '#000000',
                    borderRadius: '12px',
                    border: isDark ? '1px solid #40444F' : '1px solid #EDEEF2'
                  }}
                >
                  <HideSmall>{currentNetwork.title}</HideSmall>
                </Button>
              </HideSmall>
              <MuiMenu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                className={classes.menu}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {networks.map(eachNetwork => (
                  <CustomMenuItem key={eachNetwork.chainId} onClick={() => switchNetwrok(eachNetwork)}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                        <LogoImg src={eachNetwork.imgSrc} />
                        <BalanceText
                          style={{ flexShrink: 0, color: isDark ? '#ffff' : '#000000', display: 'initial' }}
                          mr="10px"
                          fontWeight={500}
                        >
                          {eachNetwork.title}
                        </BalanceText>
                      </div>
                      <HideBig>
                        {eachNetwork.chainId.toString() === chainId?.toString() && <FlyoutRowActiveIndicator />}
                      </HideBig>
                      <div></div>
                    </div>
                  </CustomMenuItem>
                ))}
              </MuiMenu>
            </HideNone>

            <AccountElement className="right" active={!!account} style={{ pointerEvents: 'auto' }}>
              {/* {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(3)} {Currency.getNativeCurrencySymbol(chainId)}
                </BalanceText>
              ) : null} */}
              {moxiePrice ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  ${parseFloat(moxiePrice.toString()).toFixed(2)} Moxie
                </BalanceText>
              ) : null}
              {moxiePrice && (
                <img
                  width={'24px'}
                  style={{ borderRadius: '24px', marginRight: '5px' }}
                  src="/images/tokens/moxie.png"
                  alt="logo"
                  // onClick={()=>{registerToken(
                  //   '0x13A5FB6ec4a10C3a25615F2631057277b2bC712C',
                  //   'KICK',
                  //   18,
                  //   'https://swap.kickto.app/images/tokens/icons/small.png'
                  // )}}
                />
              )}
              <Web3Status />
            </AccountElement>
          </div>
        </HeaderElement>
        <HeaderElementWrap>
          {/* <Settings /> */}
          <Menu />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}
