/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit'
import { fetchFarmsLpData } from './fetchFarms'
import {
  fetchFarmsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
  fetchUserEarnedInfo
  // fetchPoolUserHarvestInterval,
  // fetchPoolUserCanHarvestPendingReward
} from './fetchFarmsUser'
import { PoolsState, Pool } from '../types'

const initialState: PoolsState = { data: [] }

export const FarmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Pool[] = action.payload
      state.data = liveFarmsData
      // state.data = state.data.map(pool => {
      //   const livePoolData = liveFarmsData.find(entry => entry.sousId === pool.sousId)
      //   return { ...pool, ...livePoolData }
      // })
    },
    setFarmsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map(pool => {
        const userPoolData = userData.find((entry: { sousId: number }) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
    },
    updateFarmsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex(p => p.sousId === sousId)
      state.data[index] = {
        ...state.data[index],
        // @ts-ignore
        userData: { ...state.data[index].userData, [field]: value }
      }
    }
  }
})

// Actions
export const { setFarmsPublicData, setFarmsUserData, updateFarmsUserData } = FarmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = (farms: any, chainId: any) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const poolImages = []
  const PoolLpDatas = await fetchFarmsLpData(farms, chainId)
  const liveData = farms.map(pool => {
    let stakingTokenImg = ''
    let quoteTokenImg = ''
    if (poolImages && poolImages.tokens && poolImages.tokens.length > 0) {
      const stakingToken = poolImages.tokens.find(
        (eachToken: { symbol: string | undefined }) => eachToken.symbol === pool.stakingTokenName
      )
      if (stakingToken && stakingToken.logoURI) {
        stakingTokenImg = stakingToken.logoURI
      }
      const quoteToken = poolImages.tokens.find(
        (eachToken: { symbol: string | undefined }) => eachToken.symbol === pool.quoteTokenSymbol
      )
      if (quoteToken && quoteToken.logoURI) {
        quoteTokenImg = quoteToken.logoURI
      }
    }
    // const blockLimit = blockLimits.find(entry => entry.sousId === pool.sousId)
    // const totalStaking = totalStakings.find(entry => entry.sousId === pool.sousId)
    const PoolLpData = PoolLpDatas.find(
      // @ts-ignore
      entry => entry.sousId === pool.sousId
    )
    return {
      stakingTokenImg,
      quoteTokenImg,
      // ...blockLimit,
      // ...totalStaking,
      // @ts-ignore
      ...PoolLpData
    }
  })
  console.log('storing in redux now')
  dispatch(setFarmsPublicData(liveData))
  console.log('stored in redux now')
}

export const fetchFarmsUserDataAsync = (account: string, farms: any) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    const allowances = await fetchFarmsAllowance(account, farms)
    const stakingTokenBalances = await fetchUserBalances(account, farms)
    const stakedBalances = await fetchUserStakeBalances(account, farms)
    const earnedInfo = await fetchUserEarnedInfo(account, farms)
    ////console.log({ earnedInfo })
    // const pendingRewards = await fetchUserPendingRewards(account)
    // const userCanHarvest = await fetchPoolUserCanHarvestPendingReward(account)
    // const harvestInterval = await fetchPoolUserHarvestInterval(account)

    const userData = farms.map(pool => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      vestingInfo: 0,
      earnedInfo: earnedInfo[pool.sousId],
      vaultDepositInfo: 0
      // pendingReward: pendingRewards[pool.sousId],
      // canHarvest: userCanHarvest[pool.sousId],
      // harvestInterval: harvestInterval[pool.sousId]
    }))

    dispatch(setFarmsUserData(userData))
  } catch (error) {
    console.error('error is', error)
  }
}

export const updateUserAllowance = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const allowances = await fetchFarmsAllowance(account)
  dispatch(
    updateFarmsUserData({
      sousId,
      field: 'allowance',
      value: allowances[sousId]
    })
  )
}

export const updateUserBalance = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const tokenBalances = await fetchUserBalances(account)
  dispatch(
    updateFarmsUserData({
      sousId,
      field: 'stakingTokenBalance',
      value: tokenBalances[sousId]
    })
  )
}

export const updateUserStakedBalance = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  dispatch(
    updateFarmsUserData({
      sousId,
      field: 'stakedBalance',
      value: stakedBalances[sousId]
    })
  )
}

export const updateUserPendingReward = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  dispatch(
    updateFarmsUserData({
      sousId,
      field: 'pendingReward',
      value: pendingRewards[sousId]
    })
  )
}

export default FarmsSlice.reducer
