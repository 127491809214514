/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useEagerConnect, useInactiveListener } from '../../hooks'
import { NetworkContextName } from '../../constants'
import Loader from '../Loader'
import { NetworkConnector } from 'connectors/NetworkConnector'
// import { useChainId } from 'state/application/hooks'

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`

const Message = styled.h2`
  color: ${({ theme }) => theme.secondary1};
`

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
  const { t } = useTranslation()
  const { active } = useWeb3React()
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName)
  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()
  const networkfinal = localStorage.getItem('networkdefault')
  // console.log(networkfinal, 'networkfinal')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const network = new NetworkConnector({
    urls: {
      1: 'https://eth-mainnet.g.alchemy.com/v2/bLOUeund0-lmpVJBse9X8IBrZCLHibiK',
      137: 'https://polygon-mainnet.g.alchemy.com/v2/wL8bPLDoiIsqs5BlQjJJRQnOpUcNvg57',
      80001: 'https://rpc-mumbai.maticvigil.com/',
      43114: 'https://api.avax.network/ext/bc/C/rpc',
      42161: 'https://arb1.arbitrum.io/rpc',
      10: 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
      1313161554: 'https://mainnet.aurora.dev'
    },
    // @ts-ignore
    // eslint-disable-next-line react-hooks/rules-of-hooks
    defaultChainId: networkfinal ? networkfinal : 137
  })

  // if (networkfinal === '137') {
  //   network = new NetworkConnector({
  //     urls: {
  //       [1]: 'https://mainnet.infura.io/v3/63273290f2b64f1d956e2a607d17b196',
  //       [137]: 'https://polygon-rpc.com/',
  //       [43114]: 'https://api.avax.network/ext/bc/C/rpc',
  //       [80001]: 'https://rpc-mumbai.matic.today'
  //     },
  //     // @ts-ignore
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     defaultChainId: 137
  //   })
  // }

  // if (networkfinal === '43114') {
  //   network = new NetworkConnector({
  //     urls: {
  //       [1]: 'https://mainnet.infura.io/v3/63273290f2b64f1d956e2a607d17b196',
  //       [137]: 'https://polygon-rpc.com/',
  //       [43114]: 'https://api.avax.network/ext/bc/C/rpc',
  //       [80001]: 'https://rpc-mumbai.matic.today'
  //     },
  //     // @ts-ignore
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     defaultChainId: 43114
  //   })
  // }

  // if (networkfinal === '80001') {
  // network = new NetworkConnector({
  //   urls: {
  //     [1]: 'https://mainnet.infura.io/v3/63273290f2b64f1d956e2a607d17b196',
  //     [137]: 'https://polygon-rpc.com/',
  //     [43114]: 'https://api.avax.network/ext/bc/C/rpc',
  //     [42161]: 'https://arb1.arbitrum.io/rpc',
  //     [10]: 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
  //     [1313161554]: 'https://mainnet.aurora.dev'
  //   },
  //   // @ts-ignore
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   defaultChainId: 80001
  // })
  // }

  // if (networkfinal === '42161') {
  //   network = new NetworkConnector({
  //     urls: {
  //       [1]: 'https://mainnet.infura.io/v3/63273290f2b64f1d956e2a607d17b196',
  //       [137]: 'https://polygon-rpc.com/',
  //       [43114]: 'https://api.avax.network/ext/bc/C/rpc',
  //       [42161]: 'https://arb1.arbitrum.io/rpc',
  //       [10]: 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
  //       [1313161554]: 'https://mainnet.aurora.dev'
  //     },
  //     // @ts-ignore
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     defaultChainId: 42161
  //   })
  // }

  // if (networkfinal === '10') {
  //   network = new NetworkConnector({
  //     urls: {
  //       [1]: 'https://mainnet.infura.io/v3/63273290f2b64f1d956e2a607d17b196',
  //       [137]: 'https://polygon-rpc.com/',
  //       [43114]: 'https://api.avax.network/ext/bc/C/rpc',
  //       [42161]: 'https://arb1.arbitrum.io/rpc',
  //       [10]: 'https://opt-mainnet.g.alchemy.com/v2/GKzt-ZZzqI1R5yuNAc4N2znNOL7OQq2r',
  //       [1313161554]: 'https://mainnet.aurora.dev'
  //     },
  //     // @ts-ignore
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     defaultChainId: 10
  //   })
  // }

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network)
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active, networkfinal, network])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true)
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null
  }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return (
      <MessageWrapper>
        <Message>{t('unknownError')}</Message>
      </MessageWrapper>
    )
  }

  // if neither context is active, spin
  if (!active && !networkActive) {
    return showLoader ? (
      <MessageWrapper>
        <Loader />
      </MessageWrapper>
    ) : null
  }

  return children
}
