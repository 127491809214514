// @ts-nocheck
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { MenuItem } from 'components/SearchModal/styleds'
import { Info } from 'react-feather'
import { ExternalLink } from 'theme'
import { darken, lighten } from 'polished'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`
const activeClassName = 'ACTIVE'

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: -5.25rem;
  `};
`

const DropDown = styled.div`
  position: absolute;
  top: 105%;
  right: -0.25rem;
  min-width: 250px;
  padding: 0.75rem 0.5rem;
  background-color: ${({ theme }) => lighten(0.1, theme.bg1)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)
  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  const [buyCryptoActive, setBuyCryptoActive] = useState(false)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem id="link" href="https://ramp.network/buy/">
            <Info size={14} />
            <div style={{ position: 'relative' }}>
              <a href="https://ramp.network/buy/" target="blank" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                {' '}
                Buy Crypto using Ramp
              </a>
            </div>{' '}
          </MenuItem>

          <MenuItem id="link" href="https://account.moonpay.com/buy">
            <Info size={14} />
            <div style={{ position: 'relative' }}>
              <a
                href="https://account.moonpay.com/buy"
                target="blank"
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
              >
                {' '}
                Buy Crypto using MoonPay
              </a>
            </div>{' '}
          </MenuItem>

          {/* <MenuItem id="link" href="https://uniswap.org/">
            <Info size={14} />
            About
          </MenuItem>
          <MenuItem id="link" href="https://uniswap.org/docs/v2">
            <BookOpen size={14} />
            Docs
          </MenuItem>
          <MenuItem id="link" href={CODE_LINK}>
            <Code size={14} />
            Code
          </MenuItem>
          <MenuItem id="link" href="https://discord.gg/EwFs3Pp">
            <MessageCircle size={14} />
            Discord
          </MenuItem> */}
          {/* <MenuItem id="link" href="https://analytics.moxieswap.com/">
            <PieChart size={14} />
            Analytics
          </MenuItem> */}
          {/* {account && (
            <ButtonPrimary onClick={openClaimModal} padding="8px 16px" width="100%" borderRadius="12px" mt="0.5rem">
              Claim UNI
            </ButtonPrimary>
          )} */}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
