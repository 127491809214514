import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.MATIC_TESTNET]: '0x27A9fca03eD753f0729E3A86a6123d8C5c90b8e2',
  [ChainId.MATIC]: '0x429876c4a6f89FB470E92456B8313879DF98B63c',
  [ChainId.FUJI]: '0xe7c03fA4357b1a447C5CC00044BFf1321A91e3f5',
  [ChainId.AVALANCHE]: '0x1D414Cc86684395104B2ad4058ABA141D7464cd0',
  [ChainId.ARBITRUM]: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  [ChainId.OPTIMISM]: '0x1B991D143EEEb8830EA91ba0c11ceeA745fb9372',
  [ChainId.AURORA]: '0xfF066C54164E8769451f04c159BF69eB7C3EbC38',
  250: '',
  4002: '',
  100: '',
  56: '',
  97: '',
  1287: '',
  128: '',
  256: '',
  1666600000: '',
  1666700000: '',
  66: '',
  65: ''
}
// moxiechanges
export { MULTICALL_ABI, MULTICALL_NETWORKS }
