/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import React, { useState, useCallback } from 'react'
// import useIsArgentWallet from '../../hooks/useIsArgentWallet'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { useSousApprove } from 'hooks/useApprove'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import { useCurrencyBalance , useTokenBalance } from '../../state/wallet/hooks'
import { useCurrency } from '../../hooks/Tokens'
// import ProgressCircles from '../ProgressSteps'
import useWeb3 from 'hooks/useWeb3'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount, Pair, Token } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
// import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useStakingContract, useTokenContract } from '../../hooks/useContract'
// import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
// import { splitSignature } from 'ethers/lib/utils'
// import { StakingInfo, useDerivedStakeInfo } from '../../state/stake/hooks'
// import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { Pool } from '../../state/types'
// import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { getAddress } from 'utils/addressHelpers'
import { useDispatch } from 'react-redux'
import { fetchFarmsUserDataAsync } from 'state/farms'

// const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
//   display: flex;
//   justify-content: space-between;
//   padding-right: 20px;
//   padding-left: 20px;

//   opacity: ${({ dim }) => (dim ? 0.5 : 1)};
// `

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: Pool
  userLiquidityUnstaked: string
}

export default function StakingModalNew({ isOpen, onDismiss, poolInfo, userLiquidityUnstaked }: StakingModalProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const currency = useCurrency(poolInfo.stakingTokenAddress)
 
  const web3 = useWeb3()
  const { account , chainId } = useActiveWeb3React()

  const currency = new Token(chainId, poolInfo.stakingTokenAddress, poolInfo.tokenDecimals, 'UNI-V2', 'Uniswap V2')
  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  // const hypotheticalRewardRate = 0
  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [attemptngApprove, setAttemptingApprove] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = new Pair(new TokenAmount(poolInfo.tokens[0], '0'), new TokenAmount(poolInfo.tokens[1], '0'))
  const stakingContract = useStakingContract(getAddress(poolInfo.contractAddress))
 
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const dispatch = useDispatch();
  async function onStake() {
    setAttempting(true)
    const valInWei = web3.utils.toWei(typedValue)
    await stakingContract
      .deposit(poolInfo.sousId, valInWei, { gasLimit: 350000 })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Desposited LP`
        })
        setHash(response.hash)
        // console.log("hello")
        // dispatch(fetchFarmsUserDataAsync(account))
      })
      .catch((error: any) => {
        setAttempting(false)
       //console.log(error)
      })
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    // setSignatureData(null)
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const atMaxAmount = Boolean(userLiquidityUnstaked === typedValue)
  const handleMax = useCallback(() => {
    selectedCurrencyBalance && onUserInput(selectedCurrencyBalance?.toSignificant(6))
  }, [selectedCurrencyBalance, onUserInput])
  const tokencontract = useTokenContract(poolInfo.stakingTokenAddress)
  const { onApprove } = useSousApprove(tokencontract, poolInfo.sousId)
  const onAttemptToApprove = async () => {
    setAttemptingApprove(false)
    await onApprove()
    setAttemptingApprove(true)
  }
  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={currency}
            pair={dummyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id="stake-liquidity-token"
          />

          {/* <HypotheticalRewardRate dim={!(hypotheticalRewardRate > 0)}>
            <div>
              <TYPE.black fontWeight={600}>Weekly Rewards</TYPE.black>
            </div>

            <TYPE.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              4 UNI / week
            </TYPE.black>
          </HypotheticalRewardRate> */}

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={parseFloat(poolInfo.userData?.allowance.toString()) > 0}
              disabled={attemptngApprove || parseFloat(poolInfo.userData?.allowance.toString()) > 0}
            >
              {parseFloat(poolInfo.userData?.allowance.toString()) > 0
                ? 'Approved'
                : attemptngApprove
                ? 'Approving...'
                : 'Approve'}
            </ButtonConfirmed>
            <ButtonError
              disabled={parseFloat(poolInfo.userData?.allowance.toString()) === 0}
              // error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {attempting ? 'Staking...' : 'Deposit'}
            </ButtonError>
          </RowBetween>
          {/* <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} /> */}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Depositing Liquidity</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{typedValue} MOXIE-LP</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {typedValue} MOXIE-LP</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
