export default {
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    80001: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc',
    5: '0x27A9fca03eD753f0729E3A86a6123d8C5c90b8e2',
    137: '0x429876c4a6f89FB470E92456B8313879DF98B63c',
    1: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
    43113: '0xe7c03fA4357b1a447C5CC00044BFf1321A91e3f5',
    4: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
    42161: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
    10: '0x1B991D143EEEb8830EA91ba0c11ceeA745fb9372',
    43114: '0x1D414Cc86684395104B2ad4058ABA141D7464cd0',
    1313161554: '0xfF066C54164E8769451f04c159BF69eB7C3EbC38'
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    80001: '0x86652c1301843B4E06fBfbBDaA6849266fb2b5e7',
    5: '0x86652c1301843B4E06fBfbBDaA6849266fb2b5e7',
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    1: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    43113: '0xe7c03fA4357b1a447C5CC00044BFf1321A91e3f5'
  },
  moxie: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    80001: '0xc90E77c55422D932573c6cC35545027aCD35a642',
    5: '0x86652c1301843B4E06fBfbBDaA6849266fb2b5e7',
    137: '0x22e78b1D3b1e1Dd0635151Dc313218cD463E5688',
    1: '0x821834B5162dCf0C807da5998c8d10d2BF6F8772',
    43113: '0xe7c03fA4357b1a447C5CC00044BFf1321A91e3f5',
    42161: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    10: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8',
    43114: '0xCfac56EE64a22B03689A613b994eFA0771f36832',
    1313161554: '0x197F4d712D0544E6aE1417867e1f066fc54Dcff8'
  },
  moxieburn: {
    97: '0x000000000000000000000000000000000000dEaD',
    56: '0x000000000000000000000000000000000000dEaD',
    80001: '0x000000000000000000000000000000000000dEaD',
    5: '0x000000000000000000000000000000000000dEaD',
    137: '0x000000000000000000000000000000000000dEaD',
    1: '0x000000000000000000000000000000000000dEaD',
    43113: '0x000000000000000000000000000000000000dEaD',
    42161: '0x000000000000000000000000000000000000dEaD',
    10: '0x000000000000000000000000000000000000dEaD',
    43114: '0x000000000000000000000000000000000000dEaD',
    1313161554: '0x000000000000000000000000000000000000dEaD'
  },
  farm: {
    97: '',
    56: '',
    80001: '0xC9F9A2E957CC655C34276E95603103ee14E20848',
    5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
    1: '0x72ac137EB15635A1F371bD89d0a11742F8337214',
    137: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54', // wrong,
    42161: '0x3262CC62d2F1dF980DA7897335705B4C29d6fb3C',
    10: '0x4bAA01eA8bB609621B57D02d35a6e56c24009453',
    43114: '0x9A0b0Dc18Fa5d56d2c7Ac90Fa70DA1887b0c3485',
    1313161554: '0x6Ad55dAe3d7926a8bCac262F565bE85b1Be1eA54'
  }
}
